import React, { useCallback, useContext, useMemo, useState } from 'react';

const LoadedModifierGroupsContext = React.createContext({
  loadedModifierGroups: [],
  updateLoadedModifierGroups: () => {},
});

export const useLoadedModifierGroups = () => useContext(LoadedModifierGroupsContext);

export const LoadedModifierGroupsProvider = ({ children, initialModifierGroups }) => {
  const [loadedModifierGroups, setLoadedModifierGroups] = useState([...initialModifierGroups]);

  // Add newly queried modifier groups; replace if already existing in array
  const updateLoadedModifierGroups = useCallback((queriedModifierGroups) => {
    const queriedModifierGroupIds = queriedModifierGroups.map(mg => mg.id);
    const filteredModifierGroups = loadedModifierGroups.filter(mg => !queriedModifierGroupIds.includes(mg.id));
    setLoadedModifierGroups([...filteredModifierGroups, ...queriedModifierGroups]);
  }, [loadedModifierGroups]);

  const contextValue = useMemo(() => ({ loadedModifierGroups, updateLoadedModifierGroups }), [loadedModifierGroups, updateLoadedModifierGroups]);

  // Return context provider to be used as component wrapper
  return (
    <LoadedModifierGroupsContext.Provider value={contextValue}>
      {children}
    </LoadedModifierGroupsContext.Provider>
  );
};
