/* eslint-disable */
// @ts-nocheck FIXME: will be fixed in the follow-up PR
import React from 'react';

type JSONLDProps = {
  // This type accepts `undefined` values in the JSON object
  // That's why we don't use the Json type from `global.d.ts`
  // The properties with `undefined` values are removed from the stringified JSON
  children: Record<string, unknown> | string;
}

const JSONLD = ({ children }: JSONLDProps) => (
  <script
    dangerouslySetInnerHTML={{
      __html: typeof children === 'string' ? children : JSON.stringify(children),
    }}
    type="application/ld+json"
  />
);

export default JSONLD;
