import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';
import { Typography } from '@popmenu/common-ui';
import { compose, mapProps } from '@shakacode/recompose';

import { createEvent } from '~/utils/eventable';
import { formatPhone } from '../../../utils/utils';
import { withRestaurant } from '../../../utils/withRestaurant';
import { withIntl } from '../../../utils/withIntl';
import { classNames, withStyles } from '../../../utils/withStyles';
import menuItemDetailsTabsStyles from './styles';

import CustomContent from '../../shared/CustomContent';
import Grid from '../../../shared/Grid';
import ReservationWidget from '../../shared/ReservationWidget';
import ReviewForm from '../../reviews/ReviewForm';
import ScrollableMenuItemReviews from '../ScrollableMenuItemReviews';
import { AH } from '../../shared/AccessibleHeading';

const RESTAURANT_CUSTOM_TAB = 'RESTAURANT_CUSTOM_TAB';
const LOCATION_CUSTOM_TAB = 'LOCATION_CUSTOM_TAB';
const RESERVE_TAB = 'RESERVE_TAB';
const REVIEW_TAB = 'REVIEW_TAB';

class MenuItemDetailsTabs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: props.isLocationCustomTabDefault ? LOCATION_CUSTOM_TAB : REVIEW_TAB,
    };
    this.panelRef = React.createRef();
  }

  renderTab(tab) {
    const { reviewsCount } = this.props.menuItem;
    let tabName;
    switch (tab) {
      case RESTAURANT_CUSTOM_TAB:
        tabName = this.props.menuItem.restaurant.customDishTabName;
        break;
      case LOCATION_CUSTOM_TAB:
        tabName = this.props.menuItem.section.menu.location.customDishTabName;
        break;
      case RESERVE_TAB:
        tabName = 'Book a Table';
        break;
      case REVIEW_TAB:
        tabName = reviewsCount > 0 ? `Reviews (${reviewsCount})` : 'Reviews';
        break;
      default:
        return null;
    }
    return (
      <Tab
        key={tab}
        className={classNames(this.props.classes.tab, 'pm-menu-item-tab')}
        label={tabName}
        data-cy-tab-type={tab}
        onClick={() => {
          if (this.panelRef.current) {
            this.panelRef.current.focus();
          }
        }}
        value={tab}
      />
    );
  }

  renderReserveTab() {
    const { classes, menuItem, restaurantId, t } = this.props;
    const { menu } = menuItem.section;
    const { location } = menu;
    if (!location.allowReservations || !menu.allowReservation) {
      return null;
    }
    const displayReservationWidget = !!(location.openTableId || location.resyVenueId || location.yelpFriendlyId);
    return (
      <section className={classes.reserveContainer}>
        <AH typography className={classes.reserveHeading} variant="h4">
          <FormattedMessage
            id="dishes.details.reserve_heading"
            defaultMessage="Ready to join us? Great!"
          />
        </AH>
        <Typography className={classes.reserveContent}>
          {t(`dishes.details.reserve_body${displayReservationWidget ? '_form' : ''}`)}
        </Typography>
        {location.phone && (
          <Typography className={classes.reserveCall}>
            <a
              aria-label={`${location.phone} ${this.props.t('generic.telephone')}`}
              href={`tel:${location.phone}`}
              onClick={() => {
                createEvent({
                  eventableId: menuItem.id,
                  eventableType: 'MenuItem',
                  eventType: 'call_attempt_event',
                  restaurantId,
                });
              }}
            >
              {formatPhone(location.phone) || location.phone}
            </a>
          </Typography>
        )}
        {displayReservationWidget && (
          <React.Fragment>
            <div className={classes.reserveOr}>
              <span>
                <FormattedMessage id="generic.or" defaultMessage="or" />
              </span>
            </div>
            <ReservationWidget
              eventableId={menuItem.id}
              eventableType="MenuItem"
              location={location}
              widgetId={`menu-item-${menuItem.id}`}
            />
          </React.Fragment>
        )}
      </section>
    );
  }

  renderPanel() {
    switch (this.state.selectedTab) {
      case RESTAURANT_CUSTOM_TAB:
        return (
          <CustomContent
            html={this.props.menuItem.restaurant.customDishTabContent}
            id="custom-tab"
          />
        );
      case LOCATION_CUSTOM_TAB:
        return (
          <CustomContent
            html={this.props.menuItem.section.menu.location.customDishTabContent}
            id="location-custom-tab"
          />
        );
      case RESERVE_TAB:
        return this.renderReserveTab();
      case REVIEW_TAB:
        return (
          <React.Fragment>
            <ReviewForm
              dishId={this.props.menuItem.dishId}
              menuItem={this.props.menuItem}
              restaurant={this.props.menuItem.restaurant}
            />
            <div className={this.props.classes.reviewsContainer}>
              <ScrollableMenuItemReviews
                includeItemLink={this.props.includeItemLink}
                menuItem={this.props.menuItem}
                restaurantId={this.props.restaurantId}
              />
            </div>
          </React.Fragment>
        );
      default:
        return null;
    }
  }

  render() {
    const { classes, menuItem, restaurantId } = this.props;
    const { restaurant } = menuItem;
    const { menu } = menuItem.section;
    const { location } = menu;

    // Only include enabled tabs
    const tabs = [REVIEW_TAB];
    if (location.customDishTabName && location.customDishTabContent) {
      tabs.push(LOCATION_CUSTOM_TAB);
    } else if (restaurant.customDishTabName && restaurant.customDishTabContent) {
      tabs.push(RESTAURANT_CUSTOM_TAB);
    }
    if (location.allowReservations && menu.allowReservation) {
      tabs.push(RESERVE_TAB);
    }

    return (
      <section className={classes.tabsContainer}>
        <Grid container>
          {tabs.length > 1 && (
          <Grid className={classes.tabsSticky} item xs={12}>
            <Tabs
              className="pm-menu-item-tabs"
              onChange={(e, selectedTab) => {
                this.setState({ selectedTab });
                createEvent({
                  eventableId: menuItem.id,
                  eventableType: 'MenuItem',
                  eventType: `${selectedTab.toLowerCase()}_event`,
                  restaurantId,
                });
              }}
              value={this.state.selectedTab}
              variant="fullWidth"
            >
              {tabs.map(tab => this.renderTab(tab))}
            </Tabs>
          </Grid>
          )}
          <Grid item xs={12}>
            <div ref={this.panelRef} tabIndex="-1">
              {this.renderPanel()}
            </div>
          </Grid>
        </Grid>
      </section>
    );
  }
}

MenuItemDetailsTabs.defaultProps = {
  isLocationCustomTabDefault: false,
};

MenuItemDetailsTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  includeItemLink: PropTypes.bool.isRequired,
  isLocationCustomTabDefault: PropTypes.bool,
  menuItem: PropTypes.shape({
    dishId: PropTypes.number,
    id: PropTypes.number,
    restaurant: PropTypes.shape({
      customDishTabContent: PropTypes.string,
      customDishTabName: PropTypes.string,
    }).isRequired,
    reviews: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    section: PropTypes.shape({
      menu: PropTypes.shape({
        allowReservation: PropTypes.bool,
        location: PropTypes.shape({
          allowReservations: PropTypes.bool,
          customDishTabContent: PropTypes.string,
          customDishTabName: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  openReviewModal: PropTypes.func.isRequired,
  restaurantId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withStyles(menuItemDetailsTabsStyles),
  withIntl,
  withRestaurant,
  mapProps(({ restaurant, ...props }) => ({
    ...props,
    restaurantId: restaurant.id,
  })),
)(MenuItemDetailsTabs);
