import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Mutation } from '~/lazy_apollo/client/react/components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import reactStringReplace from 'react-string-replace';
import { Link } from 'react-router-dom';
import { Avatar, Button, Icon, Typography } from '@popmenu/common-ui';

import { ThumbsUp, Edit } from '@popmenu/web-icons';
import { isNotTabKey } from '../../../utils/events';
import { formatDateAgo } from '../../../utils/time';
import { reviewUpvoteFromSession } from '../../../utils/sessions';
import { withRestaurant } from '../../../utils/withRestaurant';
import { themeShape, withTheme } from '../../../utils/withTheme';
import { withStyles } from '../../../utils/withStyles';
import { currentSessionShape, withCurrentSession } from '../../../shared/CurrentSessionProvider';
import { openMenuItemModal } from '../../../shared/DishActions';
import { setVipData, openVipV2Modal } from '../../../shared/ModalActions';
import menuItemReviewStyles from './styles';
import toggleReviewVoteMutation from '../../../libs/gql/mutations/reviews/toggleReviewVoteMutation.gql';

const MenuItemReview = (props) => {
  const { classes, currentSession, includeItemLink, menuItem, ownReview, restaurant, review, summary, showReviewForm } = props;
  const displayName = review.user ? review.user.displayName : 'Guest';
  const avatarUrl = (review.user && review.user.thumbnailUrl) || review.sessionAvatarUrl;

  if (summary) {
    return (
      <React.Fragment>
        <Typography className={classes.summaryContent}>
          <FormattedMessage
            id="review_reply.content"
            defaultMessage='"{content}"'
            values={{ content: review.content }}
          />
        </Typography>
        <Avatar
          alt={displayName}
          className={classes.summaryAvatar}
          size="small"
          src={avatarUrl}
        />
      </React.Fragment>
    );
  }

  let reviewContent = review.content;
  if (review.linkedDishes && review.linkedDishes.length > 0) {
    review.linkedDishes.forEach((linkedDish) => {
      const { dish } = linkedDish;
      const dishItems = dish.items.filter(i => i.masterItemId);
      if (dishItems && dishItems.length > 0) {
        // Prefer to find an item at the same location
        const item = dishItems.find(d => d.menu.location && d.menu.location.id === menuItem.section.menu.locationId) ||
          dishItems.find(i => i.url.startsWith('/')) ||
          dishItems[0];
        const useLink = item.url.startsWith('/');

        reviewContent = reactStringReplace(reviewContent, dish.name, (match, i) => {
          if (includeItemLink) {
            if (useLink) {
              return (
                <Link
                  key={`${match}${i}`}
                  className={classes.menuItemLink}
                  to={item.url}
                >
                  {match}
                </Link>
              );
            }
            return <a className={classes.menuItemLink} href={item.url}>{match}</a>;
          }
          /* eslint-disable jsx-a11y/anchor-is-valid */
          return (
            <a
              className={classes.menuItemLink}
              onClick={(e) => {
                e.preventDefault();
                props.openMenuItemModal(item.id);
                return false;
              }}
              onKeyUp={(e) => {
                if (isNotTabKey(e)) {
                  e.preventDefault();
                  props.openMenuItemModal(item.id);
                  return false;
                }
                return true;
              }}
              role="button"
              tabIndex={0}
            >
              {match}
            </a>
          );
          /* eslint-enable jsx-a11y/anchor-is-valid */
        });
      }
    });
  }

  const renderReviewVote = () => (
    <Mutation mutation={toggleReviewVoteMutation}>
      {(toggleReviewVote, { loading }) => {
        const reviewUpvote = reviewUpvoteFromSession(currentSession, review.id);
        return (
          <div className={classes.reviewUpvoteContainer}>
            <Button
              aria-label={reviewUpvote ? 'Unlike this review' : 'Like this review'}
              className={classes.reviewButton}
              color="inherit"
              disabled={loading}
              startIcon={<Icon icon={ThumbsUp} />}
              size="small"
              onClick={(e) => {
                e.preventDefault();
                toggleReviewVote({
                  variables: {
                    reviewId: review.id,
                  },
                }).then(() => {
                  if (!currentSession.user) {
                    props.setVipData({ isReviewPath: true });
                    props.openVipV2Modal();
                  }
                });
              }}
              variant="contained"
            >
              {review.reviewUpvotesCount ? review.reviewUpvotesCount : null}
            </Button>
            {
              ownReview && showReviewForm && <Button onClick={showReviewForm} startIcon={<Icon icon={Edit} />} />
            }
          </div>
        );
      }}
    </Mutation>
  );

  const renderRestaurantReview = ({ reply }) => {
    if (reply && reply.isPublic) {
      return (
        <div className={classes.reviewReplyContainer}>
          <section className={classes.reviewReplyWrapper}>
            <Typography className={classes.reviewReplyRestaurant} paragraph>
              <FormattedMessage id="menu_item_review.reply_from" defaultMessage="Reply from {name}" values={{ name: restaurant.name }} />
            </Typography>
            <Typography className={classes.reviewReplyContent}>{reply.content}</Typography>
          </section>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classes.reviewContainer}>
      <Avatar
        alt={displayName}
        className={classes.reviewAvatar}
        size="small"
        src={avatarUrl}
      />
      <Typography className={classes.reviewDate}>
        {review.approved ? formatDateAgo(review.createdAt) : <FormattedMessage id="reviews.pending" defaultMessage="Pending" />}
      </Typography>
      <Typography className={classes.reviewAuthor} align="left">
        {displayName}
      </Typography>
      <Typography className={classes.reviewContent} align="left">
        {reviewContent}
      </Typography>
      {renderReviewVote()}
      {renderRestaurantReview(review)}
    </div>
  );
};

MenuItemReview.defaultProps = {
  ownReview: false,
  summary: false,
};

MenuItemReview.propTypes = {
  classes: PropTypes.object.isRequired,
  currentSession: currentSessionShape.isRequired,
  includeItemLink: PropTypes.bool.isRequired,
  openMenuItemModal: PropTypes.func.isRequired,
  openVipV2Modal: PropTypes.func.isRequired,
  ownReview: PropTypes.bool,
  review: PropTypes.shape({
    content: PropTypes.string,
    createdAt: PropTypes.string,
    linkedDishes: PropTypes.arrayOf(PropTypes.shape({
      dish: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({
          masterItemId: PropTypes.number,
          url: PropTypes.string,
        })),
        name: PropTypes.string,
      }),
    })),
    session: PropTypes.shape({
      avatarUrl: PropTypes.string,
    }),
    user: PropTypes.shape({
      thumbnailUrl: PropTypes.string,
    }),
  }).isRequired,
  setVipData: PropTypes.func.isRequired,
  summary: PropTypes.bool,
  theme: themeShape.isRequired,
};

export default compose(
  withCurrentSession,
  withRestaurant,
  withTheme,
  withStyles(menuItemReviewStyles),
  connect(() => ({}), { openMenuItemModal, openVipV2Modal, setVipData }),
)(MenuItemReview);
