import { readableFontColor } from '../../../utils/colors';

const menuItemReviewStyles = theme => ({
  menuItemLink: {
    color: readableFontColor('#FFFFFF', theme.palette.primary.main),
    textDecoration: 'underline',
  },
  reviewAuthor: {
    color: '#78706D',
    fontSize: '0.8em',
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(6),
  },
  reviewAvatar: {
    border: '1px solid #000',
    float: 'left',
  },
  reviewButton: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: readableFontColor('#FFF', theme.palette.primary.main),
  },
  reviewContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  reviewContent: {
    fontSize: '1em',
    marginLeft: theme.spacing(6),
  },
  reviewDate: {
    color: '#78706D',
    float: 'right',
    fontSize: '0.8em',
    marginLeft: theme.spacing(0.5),
  },
  reviewReplyButton: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  reviewReplyContainer: props => ({
    borderLeft: `4px solid ${props.theme.primaryColor}`,
    display: 'block',
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  }),
  reviewReplyContent: {
    fontSize: '1em',
  },
  reviewReplyRestaurant: {
    fontSize: '0.8em',
  },
  reviewReplyWrapper: {
    color: '#78706D',
  },
  reviewUpvoteContainer: {
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(1),
    textAlign: 'left',
  },
  summaryAvatar: {
    border: '1px solid #000',
    marginTop: theme.spacing(1),
  },
  summaryContent: {
    fontSize: '1em',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
});

export default menuItemReviewStyles;
