import React from 'react';
import { Icon, Button, IconButton } from '@popmenu/common-ui';
import { Facebook, Linkedin, Mail, Smartphone, Twitter, Send, X as XIcon } from '@popmenu/web-icons';
import { compose, mapProps } from '@shakacode/recompose';
import { connect, type ConnectedProps } from 'react-redux';
import { List, ListItem, Popover } from '@material-ui/core';
import type { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { createEvent } from '~/utils/eventable';
import { closestElement, isMobile } from '../../../utils/dom';
import { toQS } from '../../../utils/urls';
import { withRestaurant, type WithRestaurantProps } from '../../../utils/withRestaurant';
import { openSocialShareModal } from '../../../shared/ModalActions';
import { withStyles } from '../../../utils/withStyles';
import shareButtonStyles from './styles';

type ShareButtonProps = {
  children: React.ReactNode,
  eventableId?: Optional<number>,
  eventableType?: Optional<string>,
  modernLayout?: boolean,
  photoUrl?: Optional<string>,
  quote: string,
  subject?: string,
  url: string,
};

// Need to create all these types to make it easy to wrap the component with HOCs
// TODO: group these types into a single type after replacing HOCs with hooks
type ShareButtonStyleProps = {
  classes: ClassNameMap<keyof ReturnType<typeof shareButtonStyles>>,
};

type ShareButtonRestaurantProps = {
  fallbackPhotoUrl: Optional<string>,
  restaurantId: number,
}

const connector = connect(() => ({}), { openSocialShareModal });
type ShareButtonDispatchProps = ConnectedProps<typeof connector>;

type InnerShareButtonProps = ShareButtonProps &
  ShareButtonStyleProps &
  ShareButtonRestaurantProps &
  ShareButtonDispatchProps;

type ShareButtonState = {
  isMobile: boolean,
  menuAnchor: HTMLElement | null,
};

type ShareType = 'email' | 'sms' | 'facebook' | 'twitter' | 'linkedin';

class ShareButton extends React.PureComponent<InnerShareButtonProps, ShareButtonState> {
  static defaultProps = {
    eventableId: null,
    eventableType: null,
    fallbackPhotoUrl: null,
    modernLayout: false,
    photoUrl: null,
    subject: 'Hey, check out this restaurant',
  };

  constructor(props: InnerShareButtonProps) {
    super(props);
    this.state = {
      isMobile: false,
      menuAnchor: null,
    };
  }

  componentDidMount() {
    this.setState({ isMobile: isMobile() });
  }

  openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ menuAnchor: e.currentTarget });
  };

  closeMenu = () => {
    this.setState({ menuAnchor: null });
  };

  handleShare(e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>, shareType: ShareType) {
    if (this.props.eventableId && this.props.eventableType) {
      createEvent({
        eventableId: this.props.eventableId,
        eventableType: this.props.eventableType,
        eventType: `${shareType}_share_event`,
        restaurantId: this.props.restaurantId,
      });
    }
    if (shareType === 'email' || shareType === 'sms') {
      // When available, use native sharing mechanism of the device, otherwise fallback to the copy link modal
      if (typeof navigator !== 'undefined' && navigator.share) {
        e.preventDefault();
        // The URL is automatically appended to the message, so strip it out of the text
        let text = this.props.quote;
        if (this.props.url) {
          text = text.replace(this.props.url, '');
        }
        void navigator.share({
          text,
          title: document.title,
          url: this.props.url,
        });
        return false;
      }
      this.props.openSocialShareModal(this.props.quote);
      return true;
    }
    e.preventDefault();
    const link = closestElement(e.target, 'a');
    const href = (link && link.href) || '';
    const top = (window.innerHeight / 2) - 275;
    const left = (window.innerWidth / 2) - 225;
    window.open(
      href,
      'shareWindow',
      `height=450, width=550, top=${top}, left=${left}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`,
    );
    return false;
  }

  photoUrl() {
    return this.props.photoUrl || this.props.fallbackPhotoUrl || '';
  }

  render() {
    const { classes, eventableId, eventableType, fallbackPhotoUrl, modernLayout, openSocialShareModal: openSocialShareModalProp, photoUrl, quote, restaurantId, subject, url, ...buttonProps } = this.props;
    if (!quote) {
      return null;
    }

    // SMS (only iOS consistently supports `sms:` links without a phone number target)
    let smsUrl = null;
    if (this.state.isMobile && typeof navigator !== 'undefined' && navigator.userAgent) {
      if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPod') || navigator.userAgent.includes('iPad')) {
        smsUrl = `sms:&body=${encodeURIComponent(quote)}`;
      }
    }

    // Facebook
    const fbData = {
      picture: this.photoUrl(),
      quote,
      u: url,
    };
    const fbUrl = `https://www.facebook.com/sharer/sharer.php?${toQS(fbData)}`;

    // Twitter
    const twttrData = {
      text: quote,
    };
    const twttrUrl = `https://twitter.com/intent/tweet?${toQS(twttrData)}`;

    // Linkedin
    const linkedinData = {
      summary: quote,
      title: subject,
      url,
    };
    const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&${toQS(linkedinData)}`;
    const shareMenuId = 'share-item-menu';
    return (
      <React.Fragment>
        {
          modernLayout ? (
            <IconButton
              className={classes.modernShareButton}
              data-tour-id="share-options-button"
              onClick={this.openMenu}
            >
              <Icon size="large" icon={Send} />
            </IconButton>
          ) : (
            <Button
              variant="contained"
              {...buttonProps}
              aria-owns={this.state.menuAnchor ? 'share-menu' : undefined}
              data-cy="share_popup"
              onClick={this.openMenu}
              aria-expanded={Boolean(this.state.menuAnchor)}
              aria-controls={shareMenuId}
            />
          )
        }
        <Popover
          anchorEl={this.state.menuAnchor}
          id="share-menu"
          open={Boolean(this.state.menuAnchor)}
          onClose={this.closeMenu}
          className={classNames([modernLayout && classes.modernSharePopover])}
          disableAutoFocus
        >
          <IconButton
            aria-label="Close share options menu"
            className={classes.closeShareMenu}
            data-tour-id="share-options-close-menu"
            onClick={() => this.closeMenu()}
          >
            <Icon icon={XIcon} size="medium" />
          </IconButton>
          <List id={shareMenuId}>
            {this.state.isMobile && (
              <ListItem
                className={classes.menuItem}
                component={smsUrl ? 'a' : 'button'}
                href={smsUrl ?? undefined}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => this.handleShare(e, 'sms')}
              >
                <Icon icon={Smartphone} /> <FormattedMessage id="share.sms" defaultMessage="Share via SMS" />
              </ListItem>
            )}
            <ListItem
              className={classes.menuItem}
              component="a"
              href={`mailto:?subject=${encodeURIComponent('Check this out!')}&body=${encodeURIComponent(quote)}`}
              onClick={e => this.handleShare(e, 'email')}
            >
              <Icon icon={Mail} /> <FormattedMessage id="share.email" defaultMessage="Share via email" />
            </ListItem>
            <ListItem
              className={classes.menuItem}
              component="a"
              href={fbUrl}
              onClick={e => this.handleShare(e, 'facebook')}
            >
              <Icon icon={Facebook} /> <FormattedMessage id="share.facebook" defaultMessage="Share via Facebook" />
            </ListItem>
            <ListItem
              className={classes.menuItem}
              component="a"
              href={twttrUrl}
              onClick={e => this.handleShare(e, 'twitter')}
            >
              <Icon icon={Twitter} />  <FormattedMessage id="share.twitter" defaultMessage="Share via Twitter" />
            </ListItem>
            <ListItem
              className={classes.menuItem}
              component="a"
              href={linkedinUrl}
              onClick={e => this.handleShare(e, 'linkedin')}
            >
              <Icon icon={Linkedin} /> <FormattedMessage id="share.linkedin" defaultMessage="Share via Linkedin" />
            </ListItem>
          </List>
        </Popover>
      </React.Fragment>
    );
  }
}

export default compose<InnerShareButtonProps, ShareButtonProps>(
  withStyles(shareButtonStyles),
  withRestaurant,
  mapProps<
    ShareButtonProps & ShareButtonStyleProps & ShareButtonRestaurantProps,
    ShareButtonProps & ShareButtonStyleProps & WithRestaurantProps
  >(({ restaurant, ...props }) => ({
    fallbackPhotoUrl: restaurant.logoUrl,
    restaurantId: restaurant.id,
    ...props,
  })),
  connector,
)(ShareButton);
