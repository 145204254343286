import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '~/lazy_apollo/client';

import { currentSessionShape, withCurrentSession } from '../../shared/CurrentSessionProvider';
import { sortByKey } from '../../utils/arrays';
import { reviewFromSession } from '../../utils/sessions';
import menuItemReviewsQuery from '../../libs/gql/queries/reviews/menuItemReviewsQuery.gql';

import Loading from '../../shared/Loading';
import MenuItemReview from './MenuItemReview/MenuItemReview';
import { useScrollObserver } from '../../utils/useScrollObserver';
import { visualRegressionsMode } from '../../utils/visualRegressionsMode';

const LIMIT = 10;

const ScrollableMenuItemReviews = ({ currentSession, menuItem, includeItemLink, showReviewForm }) => {
  const { data, fetchMore, loading } = useQuery(
    menuItemReviewsQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        approved: true,
        menuItemId: menuItem.id,
        pagination: {
          limit: LIMIT,
          offset: 0,
          sortBy: 'createdAt',
          sortDir: 'desc',
        },
      },
    },
  );
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { dishId } = menuItem;
  const reviews = (data && data.menuItem && data.menuItem.paginatedReviews && data.menuItem.paginatedReviews.records) || [];

  const [allFetched, setAllFetched] = useState(false);
  const [hasPendingFetch, setHasPendingFetch] = useState(false);
  const { scrolledTo, scrollObserverRef } = useScrollObserver({ reset: hasPendingFetch });

  useEffect(() => {
    if (allFetched || !scrolledTo || loading || hasPendingFetch) return;
    const offset = reviews.length;

    console.log(`[POPMENU] Loading ScrollableMenuItemReviews.fetchMoreReviews ${reviews.length}-${reviews.length + LIMIT} ...`);
    setHasPendingFetch(true);

    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!mounted.current) return prev;

        // Not really necessary. Checking against paginatedReviews.count should be enough. Keeping the extra check just in case.
        if (!fetchMoreResult || !fetchMoreResult.menuItem) {
          setAllFetched(true);
          return prev;
        }
        const records = [...prev.menuItem.paginatedReviews.records];
        fetchMoreResult.menuItem.paginatedReviews.records.forEach((record) => {
          if (!records.some(r => r.id === record.id)) {
            records.push(record);
          }
        });

        if (records.length >= fetchMoreResult.menuItem.paginatedReviews.count || visualRegressionsMode) {
          setAllFetched(true);
        }
        setTimeout(() => mounted.current && setHasPendingFetch(false), 0);

        return {
          ...prev,
          menuItem: {
            ...prev.menuItem,
            paginatedReviews: {
              ...prev.menuItem.paginatedReviews,
              count: fetchMoreResult.menuItem.paginatedReviews.count,
              records,
            },
          },
        };
      },
      variables: {
        menuItemId: menuItem.id,
        pagination: {
          limit: LIMIT,
          offset,
          sortBy: 'createdAt',
          sortDir: 'desc',
        },
      },
    });
  }, [allFetched, fetchMore, hasPendingFetch, loading, menuItem.id, mounted, reviews.length, scrolledTo]);

  // Initial loading state
  if (!data || !data.menuItem) {
    return <Loading size="md" />;
  }

  // Add user's review even if it isn't approved.
  let sortedReviews = [...reviews];
  let userReview = reviewFromSession(currentSession, dishId);
  if (!!userReview && !userReview?.approved && !reviews.some(review => review.id === userReview.id)) {
    if (currentSession.user) {
      userReview = { ...userReview, user: currentSession.user };
    }
    sortedReviews.push(userReview);
  }
  sortedReviews = sortByKey(sortedReviews, 'createdAt').reverse();
  if (sortedReviews.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      {sortedReviews.map(review => (
        <aside key={review.id}>
          <MenuItemReview
            includeItemLink={includeItemLink}
            menuItem={menuItem}
            review={review}
            showReviewForm={showReviewForm}
            ownReview={userReview && userReview.id === review.id}
          />
        </aside>
      ))}
      {!allFetched && (
        <Loading containerRef={scrollObserverRef} size="md" />
      )}
    </React.Fragment>
  );
};

ScrollableMenuItemReviews.defaultProps = {
  showReviewForm: null,
};

ScrollableMenuItemReviews.propTypes = {
  currentSession: currentSessionShape.isRequired,
  includeItemLink: PropTypes.bool.isRequired,
  menuItem: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  restaurantId: PropTypes.number.isRequired,
  showReviewForm: PropTypes.func,
};

export default withCurrentSession(ScrollableMenuItemReviews);
