import { isBefore, parseISO } from 'date-fns';
import type { MenuDeliveryOpeningRange, MenuOrderingOpeningRange, MenuItemCart } from '~/libs/gql/types';
import { FulfillmentType } from '../../utils/types';

type GetMenuHoursParams = {
  fulfillmentType: Optional<FulfillmentType>;
  pickupHours: Optional<MenuOrderingOpeningRange[]>;
  deliveryHours: Optional<MenuDeliveryOpeningRange[]>;
};

export const getMenuHours = ({ fulfillmentType, pickupHours, deliveryHours }: GetMenuHoursParams) => {
  if (!fulfillmentType) return null;

  return fulfillmentType === FulfillmentType.Pickup ? pickupHours : deliveryHours;
};

type ShouldUseMenuHoursParams = {
  fulfillmentType: Optional<FulfillmentType>;
  isMenuHours: Optional<boolean>;
  isMenuHoursDelivery: Optional<boolean>;
};

export const shouldUseMenuHours = ({ fulfillmentType, isMenuHours, isMenuHoursDelivery }: ShouldUseMenuHoursParams) => (fulfillmentType === FulfillmentType.Pickup && isMenuHours) ||
  (fulfillmentType === FulfillmentType.Delivery && isMenuHoursDelivery) ||
  false;

type IsMenuEnabledParams = {
  menuItemCart: Optional<MenuItemCart>;
  isMenuHours: Optional<boolean>;
  isMenuHoursDelivery: Optional<boolean>;
  isOrderingAvailable: boolean;
};
export const isMenuEnabled = ({ menuItemCart, isMenuHours, isMenuHoursDelivery, isOrderingAvailable }: IsMenuEnabledParams) => {
  const fulfillmentType = menuItemCart?.fulfillmentType as FulfillmentType | undefined;
  const useMenuHours = shouldUseMenuHours({ fulfillmentType, isMenuHours, isMenuHoursDelivery });
  return !menuItemCart || !useMenuHours || (useMenuHours && isOrderingAvailable);
};

type IsOutOfStockParams = {
  outOfStock: Optional<boolean>;
  outOfStockUntil: Optional<string>;
  scheduledAt: Optional<string>;
};
export const isOutOfStock = ({ outOfStock, outOfStockUntil, scheduledAt }: IsOutOfStockParams) => {
  if (!outOfStock || !outOfStockUntil || !scheduledAt) return outOfStock;

  return isBefore(parseISO(scheduledAt), parseISO(outOfStockUntil));
};

export const isSoldOut = (orderingEventMaxAvailable: number | undefined | null) => (
  typeof orderingEventMaxAvailable === 'number' && orderingEventMaxAvailable <= 0
);

export type itemIsOrderLinkableParams = {
  menuItemIsEnabled: boolean;
  menuOrderingUrl: Optional<string>;
  menuEnabled: Optional<boolean>;
  outOfStock: Optional<boolean>;
  doordashDeliveryProhibited: Optional<boolean>;
};

// determines if an 'order now' link should be enabled from the menu item in a menu section to the ordering page
export const itemIsOrderLinkable = ({ menuItemIsEnabled, menuOrderingUrl, menuEnabled, outOfStock, doordashDeliveryProhibited }: itemIsOrderLinkableParams): boolean => (
  Boolean(menuOrderingUrl && menuItemIsEnabled && !outOfStock && menuEnabled && !doordashDeliveryProhibited)
);

// determines if the 'add to cart' button should be enabled for a menu item within the ordering section
export type itemIsOrderableParams = {
  menuItemIsEnabled: boolean;
  menuOrderingUrl: Optional<string>;
  menuEnabled: Optional<boolean>;
  invalidCateringLeadTime: Optional<boolean>;
  isMenuOrderingAvailable: Optional<boolean>;
  orderingEventId: Optional<string | number>;
  outOfStock: Optional<boolean>;
  soldOut: Optional<boolean>;
  doordashDeliveryProhibited: Optional<boolean>;
};

export const itemIsOrderable = ({ doordashDeliveryProhibited, isMenuOrderingAvailable, invalidCateringLeadTime, menuItemIsEnabled, menuEnabled, menuOrderingUrl, orderingEventId, outOfStock, soldOut }: itemIsOrderableParams): boolean => (
  Boolean(
    ((menuOrderingUrl && isMenuOrderingAvailable && menuEnabled) || orderingEventId) &&
  menuItemIsEnabled &&
  !outOfStock &&
  !soldOut &&
  !invalidCateringLeadTime &&
  !doordashDeliveryProhibited)
);
