import React from 'react';
import { Icon, Button } from '@popmenu/common-ui';
import { ArrowLeft, ArrowRight, Send } from '@popmenu/web-icons';
import PropTypes from 'prop-types';

import { classNames, withStyles } from '../../../utils/withStyles';
import menuItemDetailsBottomStyles from './styles';
import Grid from '../../../shared/Grid';
import ShareButton from '../../shared/ShareButton';
import WannaTryButton from '../../pops/WannaTryButton';

const MenuItemDetailsBottom = props => (
  <section
    className={classNames(
      'pm-menu-item-details-bottom',
      props.classes.bottom,
      props.isModal ? props.classes.bottomFixed : null,
    )}
  >
    <Grid container>
      <Grid item lg={3} xs={12}>
        {props.menuItem.previousItem && props.openMenuItemModal && (
          <Button
            className={props.classes.bottomButton}
            onClick={() => props.openMenuItemModal(props.menuItem.previousItem.id)}
            size="large"
            variant="text"
          >
            <Icon icon={ArrowLeft} /> {props.menuItem.previousItem.name}
          </Button>
        )}
      </Grid>
      <Grid item lg={3} xs={12}>
        <WannaTryButton
          className={props.classes.bottomButton}
          dishId={props.menuItem.dishId}
          menuItemId={props.menuItem.id}
        />
      </Grid>
      <Grid item lg={3} xs={12}>
        <ShareButton
          className={props.classes.bottomButton}
          eventableId={props.menuItem.id}
          eventableType="MenuItem"
          photoUrl={props.menuItem.photos.length > 0 ? props.menuItem.photos[0].photoUrl : null}
          quote={`Check out ${props.menuItem.name}: ${props.menuItem.shortLinkUrl}`}
          size="large"
          url={props.menuItem.shortLinkUrl}
          variant="text"
        >
          <Icon icon={Send} /> Share this item
        </ShareButton>
      </Grid>
      <Grid item lg={3} xs={12}>
        {props.menuItem.nextItem && props.openMenuItemModal && (
          <Button
            className={props.classes.bottomButton}
            onClick={() => props.openMenuItemModal(props.menuItem.nextItem.id)}
            size="large"
            variant="text"
          >
            {props.menuItem.nextItem.name} <Icon icon={ArrowRight} />
          </Button>
        )}
      </Grid>
    </Grid>
  </section>
);

MenuItemDetailsBottom.defaultProps = {
  openMenuItemModal: null,
};

MenuItemDetailsBottom.propTypes = {
  classes: PropTypes.object.isRequired,
  isModal: PropTypes.bool.isRequired,
  menuItem: PropTypes.shape({
    dishId: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    nextItem: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    photos: PropTypes.arrayOf(PropTypes.shape({
      photoUrl: PropTypes.string,
    })),
    previousItem: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    shortLinkUrl: PropTypes.string,
  }).isRequired,
  openMenuItemModal: PropTypes.func,
};

export default withStyles(menuItemDetailsBottomStyles)(MenuItemDetailsBottom);
