import React from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import type { RestaurantLocation } from '~/libs/gql/types';

import { FormattedMessage } from 'react-intl';
import { createEvent } from '~/utils/eventable';
import { htmlEncode } from '../../utils/dom';
import { toQS } from '../../utils/urls';
import { withRestaurant, type WithRestaurantProps } from '../../utils/withRestaurant';

import CustomContent from './CustomContent';

export type ReservationWidgetProps = {
  eventableId?: number;
  eventableType?: string;
  location: RestaurantLocation,
  onClick?: () => void;
  openTableIds?: string[];
  openTableTheme?: string;
  skipPopover?: boolean;
  widgetId: string;
}

type InnerReservationWidgetProps = ReservationWidgetProps & {
  restaurantId: number;
  restaurantName: string;
}

type ReservationWidgetState = {
  renderScripts: boolean;
};

class ReservationWidget extends React.PureComponent<InnerReservationWidgetProps, ReservationWidgetState> {
  handledClick: boolean;

  iframeMouseOver: boolean;

  static readonly defaultProps = {
    eventableId: undefined,
    eventableType: undefined,
    onClick: undefined,
    openTableIds: [],
    openTableTheme: 'standard',
    skipPopover: false,
  };

  constructor(props: InnerReservationWidgetProps) {
    super(props);
    this.state = {
      renderScripts: false,
    };
    this.handledClick = false;
    this.iframeMouseOver = false;
  }

  componentDidMount() {
    if (!this.state.renderScripts) {
      this.setState({ renderScripts: true });
    }
    window.addEventListener('blur', this.onWindowBlur);

    // we want to bypass the need to click the Resy button due to it being hidden
    // It has been clicked by proxy when the reservation action button is clicked
    if (this.props.skipPopover && this.props.location.resyVenueId) {
      setTimeout(() => {
        document.querySelector<HTMLElement>('.pm-reservation-widget-resy span[role="button"]')?.click();
      }, 10); // allow script complete render before clicking
    }
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.onWindowBlur);
  }

  onWindowBlur = () => {
    if (this.iframeMouseOver) {
      this.onClick();
    }
  };

  onClick = () => {
    console.log('ReservationWidget.onClick');
    if (!this.handledClick) {
      this.handledClick = true;
      if (typeof this.props.onClick === 'function') {
        this.props.onClick();
      }
      if (this.props.eventableId && this.props.eventableType) {
        createEvent({
          eventableId: this.props.eventableId,
          eventableType: this.props.eventableType,
          eventType: 'reserve_attempt_event',
          restaurantId: this.props.restaurantId,
        });
      }
    }
  };

  renderContent() {
    if (this.props.location.resyLinkUrl) {
      return (
        <a
          aria-describedby="aria-new-window-2"
          href={this.props.location.resyLinkUrl}
          id={`resy-button-${this.props.widgetId}`}
          rel="noopener"
          target="_blank"
        >
          <FormattedMessage
            id="reservationWidget.resyButton"
            defaultMessage="Book your {restaurantName} reservation on Resy"
            values={{
              restaurantName: this.props.restaurantName || '',
            }}
          />
        </a>
      );
    }

    if (this.props.location.yelpFriendlyId) {
      const title = `Reserve at ${this.props.restaurantName} on Yelp`;
      return (
        <iframe
          frameBorder="0"
          height="530"
          id="yelp-reservation-widget"
          src={`//www.yelp.com/reservations/${encodeURIComponent(this.props.location.yelpFriendlyId)}/widget?orientation=vertical&color-scheme=light`}
          title={title}
          width={/[-_]/.test(this.props.location.yelpFriendlyId) ? undefined : '750px'}
        >
          <a href={`https://www.yelp.com/reservations/${encodeURIComponent(this.props.location.yelpFriendlyId)}`}>
            {title}
          </a>
        </iframe>
      );
    }
    return null;
  }

  renderScript() {
    if (!this.state.renderScripts) {
      return null;
    }
    if (this.props.location.resyApiKey && this.props.location.resyVenueId) {
      const script = `<div><script type="text/javascript">console.log("[POPMENU] Init Resy embed..."); resyWidget.addButton(document.getElementById('${`resy-button-${this.props.widgetId}`}'), {"venueId":${this.props.location.resyVenueId},"apiKey":"${this.props.location.resyApiKey}","replace":true,});</script></div>`;
      return (
        <CustomContent id={`pm-reservation-widget-${this.props.widgetId}`} html={script} />
      );
    }
    if (this.props.location.openTableId) {
      let qs = toQS({
        domain: 'com',
        iframe: 'true',
        lang: 'en-US',
        overlay: 'false',
        rid: this.props.location.openTableId,
        theme: this.props.openTableTheme,
        type: 'standard',
      });
      if (this.props.openTableIds && this.props.openTableIds.length > 1) {
        this.props.openTableIds.forEach((openTableId) => {
          if (openTableId !== this.props.location.openTableId) {
            qs = `${qs}&rid=${openTableId}`;
          }
        });
      }
      const src = `//www.opentable.com/widget/reservation/loader?${qs}`;
      const script = `<div><script type="text/javascript">console.log("[POPMENU] Init OpenTable embed #${this.props.location.openTableId}");</script><script type="text/javascript" src="${htmlEncode(src)}"></script></div>`;
      return (
        <CustomContent id={`pm-reservation-widget-${this.props.widgetId}`} html={script} />
      );
    }
    return null;
  }

  /* eslint-disable jsx-a11y/mouse-events-have-key-events */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  render() {
    if (!this.props.location.allowReservations) {
      return null;
    }
    let type;
    if (this.props.location.resyVenueId) {
      type = 'resy';
    } else if (this.props.location.openTableId) {
      type = 'open-table';
    } else if (this.props.location.yelpFriendlyId) {
      type = 'yelp';
    } else {
      return null;
    }
    return (
      <div
        className={`pm-reservation-widget pm-reservation-widget-${type}`}
        onClick={this.onClick}
        onMouseOut={() => {
          this.iframeMouseOver = false;
        }}
        onMouseOver={() => {
          this.iframeMouseOver = true;
        }}
      >
        {this.renderContent()}
        {this.renderScript()}
      </div>
    );
  }
  /* eslint-enable jsx-a11y/mouse-events-have-key-events */
  /* eslint-enable jsx-a11y/click-events-have-key-events */
  /* eslint-enable jsx-a11y/no-static-element-interactions */
}

export default compose<InnerReservationWidgetProps, ReservationWidgetProps>(
  withRestaurant,
  mapProps<ReservationWidgetProps, ReservationWidgetProps & WithRestaurantProps>(({ restaurant, ...props }) => ({
    ...props,
    restaurantId: restaurant.id,
    restaurantName: restaurant.name,
  })),
)(ReservationWidget);
