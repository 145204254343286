export default theme => ({
  container: {
    backgroundColor: 'white',
    fontFamily: theme.typography.fontFamily && `"${theme.typography.fontFamily}"`,
    marginBottom: '-90px', // Bring up footer
  },
  containerModal: {
    marginBottom: 0,
    minHeight: '100vh',
  },
  containerTop: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      marginBottom: 0,
    },
    flexDirection: 'row-reverse',
    marginBottom: theme.spacing(-2),
  },
  dishDescriptionSkeleton: {},
  dishNameSkeleton: {
    height: '1.5rem',
    marginBottom: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(4),
  },
  dishPriceSkeleton: {
    height: '1.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
  },
  galleryContainer: {
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  gallerySkeleton: {
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      height: theme.spacing(40),
      width: '100%',
    },
    borderRadius: '4px',
    height: '50%',
    width: '80%',
  },
  infoContainer: {
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      marginTop: 0,
      overflowX: 'visible',
      overflowY: 'visible',
      paddingBottom: theme.spacing(8),
      paddingTop: theme.spacing(4),
    },
    height: '100vh',
    marginTop: theme.spacing(2),
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing(16, 2, 4, 2),
    position: 'relative',
    textAlign: 'center',
  },
  menuNameSkeleton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});
