import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { ApolloConsumer } from '~/lazy_apollo/client';
import gql from 'graphql-tag';

import { classNames, withStyles } from '../../../utils/withStyles';
import menuItemDetailsSkeletonStyles from './styles';

import Grid from '../../../shared/Grid';

const menuItemFragment = gql`
  fragment MenuItemSkeletonFragment on MenuItem {
    __typename
    id
    photosCount
  }
`;

const MenuItemDetailsSkeleton = ({ classes, isModal, menuItemId }) => (
  <ApolloConsumer>
    {(client) => {
      const menuItem = menuItemId && client.readFragment({
        fragment: menuItemFragment,
        id: `MenuItem:${menuItemId}`,
      });
      const hasPhotos = !!(menuItem && menuItem.photosCount && menuItem.photosCount > 0);
      return (
        <div
          className={classNames(
            classes.container,
            isModal ? classes.containerModal : null,
          )}
        >
          <Grid
            alignItems="stretch"
            className={classes.containerTop}
            container
            justify={hasPhotos ? 'flex-start' : 'center'}
          >
            <Grid item lg={4} xs={12}>
              <div className={classes.infoContainer}>
                <Skeleton className={classes.menuNameSkeleton} width="40%" />
                <Skeleton className={classes.menuNameSkeleton} width="40%" />
                <Skeleton className={classes.dishNameSkeleton} width="60%" />
                <Skeleton className={classes.dishPriceSkeleton} width="20%" />
                <Skeleton className={classes.dishDescriptionSkeleton} />
                <Skeleton className={classes.dishDescriptionSkeleton} />
                <Skeleton className={classes.dishNameSkeleton} width="60%" />
                <Skeleton className={classes.dishDescriptionSkeleton} />
                <Skeleton className={classes.dishDescriptionSkeleton} />
              </div>
            </Grid>
            {hasPhotos && (
              <Grid item lg={8} xs={12}>
                <div className={classes.galleryContainer}>
                  <Skeleton className={classes.gallerySkeleton} variant="rect" />
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      );
    }}
  </ApolloConsumer>
);

MenuItemDetailsSkeleton.defaultProps = {
  menuItemId: null,
};

MenuItemDetailsSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
  isModal: PropTypes.bool.isRequired,
  menuItemId: PropTypes.number,
};

export default withStyles(menuItemDetailsSkeletonStyles)(MenuItemDetailsSkeleton);
