import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { classNames, withStyles } from '../../../utils/withStyles';
import Grid from '../../../shared/Grid';
import styles from './styles';

const MenuItemModernDetailsInfoSkeleton = ({ classes }) => (
  <div
    className={classNames(
      classes.containerModal,
    )}
    id="modern-skeleton-container"
  >
    <Grid
      alignItems="stretch"
      container
    >
      <Grid item lg={12} xs={12}>
        <div className={classes.topContainer}>
          <Skeleton className={classes.description} variant="text" width="40%" />
          <Skeleton className={classes.description} variant="circle" width={40} height={40} />
        </div>
        <Skeleton className={classes.description} variant="text" width="40%" />
        <Skeleton className={classes.descriptionCenter} variant="text" width="80%" />
        <Grid item lg={12} xs={12}>
          <div className={classes.bottomContainer}>
            <Skeleton className={classes.description} variant="text" width="40%" />
            <Skeleton className={classes.tabs} variant="rect" width="90%" />
            <div className={classes.buttonContainer}>
              <Skeleton className={classes.button} variant="rect" width="30%" />
              <Skeleton className={classes.button} variant="rect" width="50%" />
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  </div>
);

MenuItemModernDetailsInfoSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuItemModernDetailsInfoSkeleton);
