import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@popmenu/common-ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';

import { createEvent } from '~/utils/eventable';
import { sortByKey } from '../../utils/arrays';
import { reviewFromSession } from '../../utils/sessions';
import useVariable from '../../utils/useVariable';
import { withIntl } from '../../utils/withIntl';
import { withRestaurant } from '../../utils/withRestaurant';
import { withSnackbar } from '../../utils/withSnackbar';
import { openVipV2Modal, setVipData } from '../../shared/ModalActions';
import { currentSessionShape, withCurrentSession } from '../../shared/CurrentSessionProvider';
import updateMenuItemReviewMutation from '../../libs/gql/mutations/menus/updateMenuItemReviewMutation.gql';
import restaurantLocationsQuery from '../../libs/gql/queries/locations/restaurantWithLocationNamesQuery.gql';

import BasicForm, { SelectGroup, SubmitGroup, TextAreaGroup } from '../../admin/shared/forms/BasicForm';
import Loading from '../../shared/Loading';
import Query from '../../shared/Query';

const ReviewFormSubmitter = ({ currentUser, formSubmitted, submitForm }) => {
  const [initialUser, setInitialUser] = useVariable(currentUser);
  const [resubmitted, setResubmitted] = useVariable(false);
  useEffect(() => {
    if (initialUser === null && currentUser && formSubmitted && !resubmitted) {
      setResubmitted(true);
      setInitialUser(currentUser);
      submitForm();
    }
  });
  return null;
};

const LocationsDropdown = ({ locations }) => {
  if (!locations || locations.length <= 1) {
    return null;
  }

  return (
    <SelectGroup
      field="locationId"
      isClearable={false}
      isSearchable={false}
      options={sortByKey(locations.map(location => ({
        label: location.name,
        value: location.id,
      })), 'label')}
      placeholder="Select a location"
      title="Which location did you visit?"
    />
  );
};

const ReviewPostedSnackbar = ({ userReview }) => (
  <Box display="flex">
    <Typography variant="body2">
      <FormattedMessage id="consumer.ordering.updated_review_emoji" defaultMessage="🎉" />
    </Typography>
    <Box marginLeft={1}>
      <Typography variant="body2">
        <FormattedMessage
          id="consumer.ordering.updated_review_message"
          defaultMessage="Your review was {review_status}!"
          values={{ review_status: userReview ? 'updated' : 'submitted' }}
        />
      </Typography>
    </Box>
  </Box>
);

const ReviewForm = (props) => {
  const { allowAnonymousReviews, callback, currentSession, dishId, menuItem, modernLayout, restaurant, t } = props;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const userReview = reviewFromSession(currentSession, dishId);
  return (
    <Query
      query={restaurantLocationsQuery}
      variables={{
        restaurantId: restaurant.id,
      }}
    >
      {({ data, loading }) => {
        if (loading || !data || !data.restaurant.locations) {
          return <Loading size="lg" />;
        }

        const locations = sortByKey(data.restaurant.locations, 'name').filter(location => location.isLocationEnabled);
        return (
          <div className="review-form-modern">
            <BasicForm
              aria-label="Add Your Review"
              defaultValues={{
                content: userReview ? userReview.content : null,
                locationId: (userReview && userReview.locationId) || menuItem.section.menu.locationId,
              }}
              mutate={{
                mutation: updateMenuItemReviewMutation,
                onCompleted: () => {
                  if (!currentSession.user) {
                    props.setVipData({ isReviewPath: true });
                    props.openVipV2Modal();
                  }
                  if (allowAnonymousReviews || currentSession.user) {
                    props.showSnackbar(
                      <ReviewPostedSnackbar userReview={userReview} />,
                      {
                        anchorOrigin: {
                          horizontal: 'right',
                          vertical: 'top',
                        },
                        autoHideDuration: 2000,
                        variant: 'info',
                      },
                    );
                  }
                  if (callback) {
                    callback();
                  }
                },
                toVariables: variables => ({
                  ...variables,
                  menuItemId: menuItem.id,
                }),
              }}
              onSubmit={() => {
                createEvent({
                  eventableId: menuItem.id,
                  eventableType: 'MenuItem',
                  eventType: 'review_attempt_event',
                  restaurantId: restaurant.id,
                });
                setFormSubmitted(true);
                if (!allowAnonymousReviews && !currentSession.user) {
                  props.setVipData({ isReviewPath: true });
                  props.openVipV2Modal();
                  return false;
                }
                return true;
              }}
            >
              {({ submitForm }) => (
                <React.Fragment>
                  <ReviewFormSubmitter
                    currentUser={currentSession.user}
                    formSubmitted={formSubmitted}
                    submitForm={submitForm}
                  />
                  <LocationsDropdown
                    locations={locations}
                  />
                  <TextAreaGroup
                    id="pm-dish-review-text-area-input"
                    field="content"
                    rows={3}
                    title="Add Your Review Here"
                  />
                  <div style={{ marginTop: '-16px' }}>
                    <SubmitGroup
                      block
                      ButtonProps={{ className: 'gtm-submit-review-button' }}
                      color="primary"
                      variant={modernLayout ? 'outlined' : 'primary'}
                      title={t(`reviews.${userReview ? 're' : ''}submit`)}
                    />
                  </div>
                </React.Fragment>
              )}
            </BasicForm>
          </div>
        );
      }}

    </Query>
  );
};

ReviewForm.defaultProps = {
  callback: null,
  modernLayout: false,
};

ReviewForm.propTypes = {
  allowAnonymousReviews: PropTypes.bool.isRequired,
  callback: PropTypes.func,
  currentSession: currentSessionShape.isRequired,
  dishId: PropTypes.number.isRequired,
  menuItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    section: PropTypes.shape({
      menu: PropTypes.shape({
        locationId: PropTypes.number,
      }),
    }),
  }).isRequired,
  modernLayout: PropTypes.bool,
  openVipV2Modal: PropTypes.func.isRequired,
  restaurant: PropTypes.shape({
    id: PropTypes.number,
    locations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  }).isRequired,
  setVipData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
  withCurrentSession,
  connect(() => ({}), {
    openVipV2Modal,
    setVipData,
  }),
  withRestaurant,
  withSnackbar,
  mapProps(({ restaurant, ...props }) => ({
    ...props,
    allowAnonymousReviews: restaurant.allowAnonymousReviews,
    restaurant,
  })),
)(ReviewForm);
