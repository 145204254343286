import { darkenToReadableColor } from '../../../utils/colors';

export default theme => ({
  addToCartButton: {
    '&:hover': {
      borderColor: darkenToReadableColor('#FFFFFF', theme.palette.primary.main),
    },
    borderColor: darkenToReadableColor('#FFFFFF', theme.palette.primary.main),
    color: darkenToReadableColor('#FFFFFF', theme.palette.primary.main),
    marginTop: theme.spacing(2),
  },
  closeButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    color: theme.consumer.primaryColorOnWhite,
    left: theme.spacing(1),
    padding: theme.spacing(1),
    position: 'absolute',
    textTransform: 'none',
    top: theme.spacing(2),
    zIndex: 2,
  },
  closeButtonWithPhotos: {
    display: 'none !important',
  },
  dishDescription: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  dishName: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  dishPrice: {
    fontSize: '1.5em',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  dishTag: {
    borderRadius: '50%',
    color: '#FFF',
    display: 'inline-block',
    fontFamily: '"Roboto Mono", monospace',
    fontSize: '0.8em',
    fontWeight: 'bold',
    height: theme.spacing(3),
    lineHeight: `${theme.spacing(3)}px`,
    margin: theme.spacing(0.25, 0.5),
    overflow: 'hidden',
    textAlign: 'center',
    width: theme.spacing(3),
  },
  dishTagImg: {
    borderRadius: '0 !important',
    objectFit: 'contain',
  },
  dishTagsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3.5),
  },
  extraGroupName: {
    '&:after': {
      content: 'none !important',
    },
  },
  extrasContainer: {
    marginTop: theme.spacing(3.5),
    textAlign: 'center',
  },
  extrasTag: {
    '&:after': {
      content: "' \u00b7 '",
    },
    '&:last-child': {
      marginRight: 0,
    },
    '&:last-child:after': {
      content: 'none',
    },
    display: 'inline-block',
    fontWeight: 'normal',
    margin: theme.spacing(0.25, 0.5),
  },
  infoContainer: {
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      marginTop: theme.spacing(2),
      overflow: 'visible',
    },
    height: '100vh',
    marginTop: 0,
    overflow: 'scroll',
    padding: theme.spacing(0, 2, 2, 2),
    position: 'relative',
    textAlign: 'center',
  },
  infoContainerModal: {
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      marginTop: 0,
      overflowX: 'visible',
      overflowY: 'visible',
      paddingBottom: theme.spacing(2),
    },
    height: theme.consumer.useLegacyDishLayout ? `calc(100vh - ${theme.spacing(4)}px)` : `calc(100vh - ${theme.spacing(10)}px)`,
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingBottom: theme.spacing(8),
  },
  infoContainerWithoutPhotos: {
    height: 'auto',
    marginTop: 0,
    minHeight: '100vh',
  },
  infoTop: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(-10),
    },
    height: theme.spacing(12),
    overflow: 'visible',
    position: 'relative',
  },
  menuName: {
    color: theme.consumer.primaryColorOnWhite,
    fontSize: '1em',
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  popBubble: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 2,
  },
  reviewButton: {
    color: theme.consumer.primaryColorOnWhite,
    fontSize: '1em',
    padding: 0,
    textDecoration: 'underline',
    textTransform: 'none',
  },
  reviewContent: {
    fontSize: '1em',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  reviewHeading: {
    color: theme.consumer.primaryColorOnWhite,
    fontSize: '1em',
    fontWeight: 'bold',
    marginTop: theme.spacing(6),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  shareButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    left: theme.spacing(-2),
    position: 'absolute',
    top: theme.spacing(2),
    zIndex: 2,
  },
  shareButtonWithoutPhotos: {
    display: 'none !important',
  },
});
