const DISLIKED_IT_COLOR = '#F8E71C';
const LIKED_IT_COLOR = '#FEA623';
const LOVED_IT_COLOR = '#E03D43';
const WANNA_TRY_COLOR = '#2B92B9';

export default theme => ({
  button: {
    alignItems: 'center',
    backgroundColor: 'white',
    border: '2px solid #78706D',
    borderRadius: '20px',
    color: '#78706D',
    display: 'flex',
    fontSize: '1em',
    justifyContent: 'space-between',
    marginLeft: '0 !important', // Embed override
    marginRight: '0 !important', // Embed override
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5, 2),
    textTransform: 'none',
    width: '100%',
  },
  'button-disliked_it-popped': {
    backgroundColor: `${DISLIKED_IT_COLOR} !important`,
    borderColor: `${DISLIKED_IT_COLOR} !important`,
    color: 'white !important',
  },
  'button-liked_it-popped': {
    backgroundColor: `${LIKED_IT_COLOR} !important`,
    borderColor: `${LIKED_IT_COLOR} !important`,
    color: 'white !important',
  },
  'button-loved_it-popped': {
    backgroundColor: `${LOVED_IT_COLOR} !important`,
    borderColor: `${LOVED_IT_COLOR} !important`,
    color: 'white !important',
  },
  'button-wanna_try-popped': {
    backgroundColor: `${WANNA_TRY_COLOR} !important`,
    borderColor: `${WANNA_TRY_COLOR} !important`,
    color: 'white !important',
  },
  popAvatar: {
    '&:first-child': {
      marginLeft: 0,
    },
    border: '2px solid #78706D !important',
    borderRadius: '50% !important',
    height: theme.spacing(4),
    marginLeft: theme.spacing(-1),
    objectFit: 'cover',
    width: theme.spacing(4),
  },
  'popAvatar-disliked_it': {
    borderColor: `${DISLIKED_IT_COLOR} !important`,
  },
  'popAvatar-liked_it': {
    borderColor: `${LIKED_IT_COLOR} !important`,
  },
  'popAvatar-loved_it': {
    borderColor: `${LOVED_IT_COLOR} !important`,
  },
  'popAvatar-wanna_try': {
    borderColor: `${WANNA_TRY_COLOR} !important`,
  },
  popAvatarsContainer: {
    height: theme.spacing(4),
    maxWidth: '50%',
    overflow: 'hidden',
  },
  popCount: {
    alignItems: 'center',
    backgroundColor: 'black',
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    fontSize: '0.8em',
    fontWeight: 'bold',
    height: theme.spacing(2),
    justifyContent: 'center',
    position: 'absolute',
    right: theme.spacing(-0.5),
    top: theme.spacing(-0.5),
    width: theme.spacing(2),
  },
  popCountContainer: {
    '&:last-child': {
      marginRight: 0,
    },
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: theme.spacing(4),
    justifyContent: 'center',
    marginRight: theme.spacing(0.5),
    position: 'relative',
    textAlign: 'center',
    width: theme.spacing(4),
  },
  'popCountContainer-disliked_it': {
    backgroundColor: `${DISLIKED_IT_COLOR} !important`,
  },
  'popCountContainer-liked_it': {
    backgroundColor: `${LIKED_IT_COLOR} !important`,
  },
  'popCountContainer-loved_it': {
    backgroundColor: `${LOVED_IT_COLOR} !important`,
  },
  'popCountContainer-wanna_try': {
    backgroundColor: `${WANNA_TRY_COLOR} !important`,
  },
  popCountIcon: {
    color: 'white',
    fontSize: '1em',
  },
  popCountsContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  popListContainer: {
    backgroundColor: 'white',
    borderRadius: '4px',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  popListHeading: {
    color: '#78706D',
    fontSize: '1.2em',
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  popListSubheading: {
    color: '#78706D',
    fontSize: '0.8em',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  popReactionsContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  shareButton: {
    alignItems: 'center',
    backgroundColor: 'white',
    border: '2px solid #78706D',
    borderRadius: '20px',
    color: '#78706D',
    display: 'flex',
    fontSize: '1em',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5, 2),
    textTransform: 'none',
    width: '100%',
  },
});
