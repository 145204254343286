import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { Button, Icon } from '@popmenu/common-ui';
import { Calendar, CheckCircle } from '@popmenu/web-icons';

import { createEvent } from '~/utils/eventable';
import { withIntl } from '../../utils/withIntl';
import { withRestaurant } from '../../utils/withRestaurant';
import { popFromSession } from '../../utils/pops';
import { currentSessionShape, withCurrentSession } from '../../shared/CurrentSessionProvider';
import updateMenuItemPopMutation from '../../libs/gql/mutations/menus/updateMenuItemPopMutation.gql';
import Mutation from '../../shared/Mutation';
import { openVipV2Modal } from '../../shared/ModalActions';

const WannaTryButton = ({ allowAnonymousPops, className, currentSession, dishId, menuItemId, restaurantId, t }) => {
  const dispatch = useDispatch();
  const userPop = popFromSession(currentSession, dishId);
  if (userPop) {
    return (
      <Button className={className} disabled size="large" variant="text">
        <Icon icon={CheckCircle} /> {t(`pops.pop_types.${userPop.popType}.popped`)}
      </Button>
    );
  }
  return (
    <Mutation mutation={updateMenuItemPopMutation}>
      {(updateMenuItemPop, { loading }) => (
        <Button
          className={className}
          disabled={loading}
          onClick={() => {
            if (loading) {
              return;
            }
            createEvent({
              eventableId: menuItemId,
              eventableType: 'MenuItem',
              eventType: 'pop_attempt_event',
              restaurantId,
            });
            if (!allowAnonymousPops && !currentSession.user) {
              dispatch(openVipV2Modal());
              return;
            }
            updateMenuItemPop({
              variables: {
                menuItemId,
                popType: 'wanna_try',
              },
            }).then(() => {
              if (!currentSession.user) {
                dispatch(openVipV2Modal());
              }
            });
          }}
          size="large"
          variant="text"
        >
          <Icon icon={Calendar} /> <FormattedMessage id="pops.pop_types.wanna_try.new" defaultMessage="Remind Me To Try This" />
        </Button>
      )}
    </Mutation>
  );
};

WannaTryButton.defaultProps = {
  allowAnonymousPops: false,
  className: null,
};

WannaTryButton.propTypes = {
  allowAnonymousPops: PropTypes.bool,
  className: PropTypes.string,
  currentSession: currentSessionShape.isRequired,
  dishId: PropTypes.number.isRequired,
  menuItemId: PropTypes.number.isRequired,
  restaurantId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withCurrentSession,
  withIntl,
  withRestaurant,
  mapProps(({ restaurant, ...props }) => ({
    ...props,
    restaurantId: restaurant.id,
  })),
)(WannaTryButton);
