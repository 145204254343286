import React, { memo, useRef, useState } from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import { CircularProgress, ClickAwayListener } from '@material-ui/core';
import { Button, Icon, Tooltip } from '@popmenu/common-ui';
import { Heart, X as XIcon } from '@popmenu/web-icons';
import { useMutation, useReactiveVar } from '~/lazy_apollo/client';
import {
  UpdateMenuItemPopDocument,
  type UpdateMenuItemPopMutation,
} from '~/libs/gql/mutations/menus/updateMenuItemPopMutation.generated';
import {
  UpdateCalendarEventPopDocument,
  type UpdateCalendarEventPopMutation,
} from '~/libs/gql/mutations/calendar_events/updateCalendarEventPopMutation.generated';
import { withCurrentSession, type WithCurrentSessionProps } from '~/shared/CurrentSessionProvider';
import {
  calendarEventPopFromSession,
  POP_ICONS,
  POP_TYPES,
  popFromSession,
  type PopIconName,
  type PopType,
} from '~/utils/pops';
import { createEvent } from '~/utils/eventable';
import { useIntl } from '../../../utils/withIntl';
import { withRestaurant, type WithRestaurantProps } from '../../../utils/withRestaurant';
import { classNames, makeStyles } from '../../../utils/withStyles';
import { type PopCounts, userPopsVar } from './userPopsVar';
import styles from './styles';
import {
  connector,
  type InnerSharedPopBubbleProps,
  type PopMutationFn,
  type PopMutationVariables,
  type SharedPopBubbleProps,
} from './shared';

const useStyles = makeStyles(styles);

const getPopTypeEvents = (disableLikes = false) => {
  if (disableLikes) {
    return POP_TYPES.filter(type => type !== 'liked_it' && type !== 'disliked_it').reverse();
  }
  return [...POP_TYPES].reverse();
};

// TODO check if we can reuse props
interface PopData {
  disableLikes?: boolean;
  likedItPopsCount?: Optional<number>;
  lovedItPopsCount?: Optional<number>;
  popsCount?: Optional<number>;
  wannaTryPopsCount?: Optional<number>;
}

export const buildPopCounts = (userPopType: Optional<PopType>, data: Optional<PopData>): PopCounts => {
  const { likedItPopsCount, lovedItPopsCount, popsCount, wannaTryPopsCount, disableLikes } = data ?? {};
  return {
    disliked_it: userPopType === 'disliked_it' ? 1 : 0,
    liked_it: likedItPopsCount,
    loved_it: lovedItPopsCount,
    modern_wanna_try: wannaTryPopsCount, // has a different name and icon but same value
    total: disableLikes ? (lovedItPopsCount ?? 0) + (wannaTryPopsCount ?? 0) : popsCount,
    wanna_try: wannaTryPopsCount,
  };
};

const getIconName = (userPopType: Optional<PopType>, data: InnerPopBubbleProps) => {
  const { lovedItPopsCount, wannaTryPopsCount, wannaTryIcon, disableLikes, showWannaTry } = data;

  if (!disableLikes && userPopType && userPopType !== 'wanna_try') {
    return POP_ICONS[userPopType];
  } else if (lovedItPopsCount > 0) {
    return Heart;
  } else if (wannaTryPopsCount > 0 && showWannaTry) {
    return POP_ICONS[wannaTryIcon ?? 'modern_wanna_try'];
  }
  return Heart;
};

export const setUpPopType = (data: InnerPopBubbleProps) => {
  const { currentSession, poppableId, poppableType } = data;
  if (poppableType === 'Dish') {
    return {
      i18nKey: 'new',
      mutation: UpdateMenuItemPopDocument,
      userPopType: popFromSession(currentSession, poppableId)?.popType as PopType | undefined,
    };
  }
  // CalendarEvent
  return {
    i18nKey: 'event',
    mutation: UpdateCalendarEventPopDocument,
    userPopType: calendarEventPopFromSession(currentSession, poppableId)?.popType as PopType | undefined,
  };
};

export const onPopButtonClick = (
  selected: boolean,
  popType: PopType,
  updatePopMutation: PopMutationFn,
  props: InnerPopBubbleProps,
) => {
  const { menuItemId, poppableId, poppableType, restaurantId, currentSession } = props;
  createEvent({
    eventableId: menuItemId ?? poppableId,
    eventableType: menuItemId != null ? 'MenuItem' : poppableType,
    eventType: 'pop_attempt_event',
    restaurantId,
  });
  let mutationVariables: PopMutationVariables;

  if (poppableType === 'Dish') {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- better to get an error if this is wrong
    mutationVariables = { menuItemId: menuItemId!, popType: selected ? null : popType };
  } else { // CalendarEvent
    mutationVariables = { calendarEventId: poppableId, popType: selected ? null : popType };
  }
  // @ts-expect-error TypeScript can't check we pass the correct variables type depending on poppableType
  void updatePopMutation({ variables: mutationVariables })
    .then(({ data: updateData }) => {
      let newPopType = null;
      let popCountData: Optional<PopData> = null;
      if (updateData) {
        if (poppableType === 'Dish') {
          const updateMenuItemData = updateData as UpdateMenuItemPopMutation;
          popCountData = updateMenuItemData.updateMenuItemPop?.item;
          newPopType = updateMenuItemData.updateMenuItemPop?.popType as Optional<PopType>;
        } else { // CalendarEvent
          const updateCalendarEventData = updateData as UpdateCalendarEventPopMutation;
          popCountData = updateCalendarEventData.updateCalendarEventPop.calendarEvent;
          newPopType = updateCalendarEventData.updateCalendarEventPop.popType as Optional<PopType>;
        }
        userPopsVar({
          ...userPopsVar(),
          [`${poppableType}:${poppableId}`]: buildPopCounts(newPopType, { ...popCountData, disableLikes: props.disableLikes }),
        });
      }
      if (!currentSession.user) {
        props.setVipData({ isPopPath: true });
        props.openVipV2Modal();
      }
    });
};

interface PopBubbleOwnProps {
  likedItPopsCount: number;
  lovedItPopsCount: number;
  menuItemName?: string;
  popsCount: number;
  wannaTryIcon?: Extract<PopIconName, `${string}wanna_try`>;
  wannaTryPopsCount: number;
}

interface PopBubbleProps extends SharedPopBubbleProps, PopBubbleOwnProps {}

interface InnerPopBubbleProps extends InnerSharedPopBubbleProps, PopBubbleOwnProps, WithCurrentSessionProps {}

const defaultProps = {
  disableLikes: false,
  showWannaTry: true,
  wannaTryIcon: 'modern_wanna_try',
} as const;

const PopBubble = ({ className = null, menuItemName = '', ...props }: InnerPopBubbleProps) => {
  const t = useIntl();
  const classes = useStyles(props);
  const bubbleRef = useRef<HTMLDivElement>(null);
  const {
    disableLikes = defaultProps.disableLikes,
    wannaTryIcon = defaultProps.wannaTryIcon,
    poppableType,
    poppableId,
    showWannaTry = defaultProps.showWannaTry,
  } = props;

  // the userPops reactive variable keeps a client-side cache of the pop counts at last "pop".
  // this ensures the count updates are not wiped out by server-side/cloudflare menu caching
  const userPops = useReactiveVar(userPopsVar);
  const [isExpanded, setIsExpanded] = useState(false);
  const { i18nKey, mutation, userPopType } = setUpPopType(props);
  const popCounts = userPops[`${poppableType}:${poppableId}`] || buildPopCounts(userPopType, props);
  let popTypeEvents = getPopTypeEvents(disableLikes);
  if (!showWannaTry) {
    popTypeEvents = popTypeEvents.filter(event => event !== 'wanna_try');
  }

  const iconName = getIconName(userPopType, props);
  const [updatePopMutation, { loading }] = useMutation(mutation);

  return ( // full expanding button with sub-buttons
    <ClickAwayListener onClickAway={() => setIsExpanded(false)}>
      <div className={classNames(classes.container, className)}>
        <div
          className={classNames(
            classes.popCountContainer,
            isExpanded ? classes.popCountContainerExpanded : null,
          )}
        >
          <span
            className={classNames(
              classes.count,
              classes.countMain,
              isExpanded || popCounts.total === 0 ? classes.hidden : null,
            )}
          >
            {popCounts.total}
          </span>
          {popTypeEvents.filter(popType => popCounts[popType] !== undefined)
            .map(popType => (
              <span key={popType} aria-hidden className={classNames(classes.count)}>
                {popCounts[popType]}
              </span>
            ))}
        </div>
        <div
          className={classNames(
            classes.toggleContainer,
            isExpanded ? classes.toggleContainerExpanded : null,
          )}
        >
          <Button
            aria-expanded={isExpanded}
            aria-label={isExpanded ? `Close ${menuItemName} ratings` : `View ${menuItemName} ratings with ${popCounts.total} pops`}
            className={classNames(
              classes.toggleButton,
              isExpanded ? classes.toggleButtonExpanded : null,
              (!isExpanded && userPopType) ? classes.toggleButtonSelected : null,
            )}
            data-cy="pop_button"
            disabled={loading}
            onClick={() => setIsExpanded(!isExpanded)}
            variant="text"
          >
            {loading ? <CircularProgress size={16} /> : (
              <Icon
                className={classNames({
                  [classes.icon]: true,
                  [classes.iconLovedIt]: userPopType === 'loved_it',
                  [classes.iconPopped]: userPopType && userPopType !== 'loved_it',
                  [classes.iconPopped]: isExpanded,
                })}
                icon={isExpanded ? XIcon : iconName}
              />
            )}
          </Button>
          {isExpanded && popTypeEvents.map((popType) => {
            const selected = userPopType === popType;
            const popIcon = popType === 'wanna_try' ?
              (showWannaTry ? POP_ICONS[wannaTryIcon] : undefined) :
              POP_ICONS[popType];
            return (
              <div ref={bubbleRef} key={`${popType}-${isExpanded}`} className={classNames(classes.popButtonContainer)}>
                <Tooltip title={t(`pops.pop_types.${popType}.${i18nKey}`)}>
                  <Button
                    aria-expanded={isExpanded}
                    aria-label={`Rate ${menuItemName} as ${t(`pops.pop_types.${popType}.${i18nKey}`)} (${popCounts[popType]} ${popCounts[popType] === 1 ? 'rating' : 'ratings'})`}
                    className={classNames(classes.popButton)}
                    data-cy={popType}
                    disabled={loading}
                    onBlur={(event) => { if (bubbleRef.current && !bubbleRef.current.contains(event.relatedTarget)) { setIsExpanded(false); } }}
                    onClick={() => {
                      if (!loading) {
                        onPopButtonClick(selected, popType, updatePopMutation, props);
                        setIsExpanded(false);
                      }
                    }}
                    variant="text"
                  >
                    {popIcon && (
                      <Icon
                        className={classNames(classes.iconExpanded, {
                          [classes.iconLovedIt]: selected && popType === 'loved_it',
                          [classes.iconPopped]: selected && popType !== 'loved_it',
                        })}
                        icon={popIcon}
                      />
                    )}
                  </Button>
                </Tooltip>
              </div>
            );
          })}
        </div>
      </div>
    </ClickAwayListener>
  );
};
// eslint-disable-next-line regex/invalid -- destructuring defaultProps complicates the code too much
PopBubble.defaultProps = defaultProps;

export default memo(compose<InnerPopBubbleProps, PopBubbleProps>(
  withCurrentSession,
  connector,
  withRestaurant,
  mapProps<
    InnerPopBubbleProps,
    Omit<InnerPopBubbleProps, 'restaurantId'> & WithRestaurantProps
  >(({ restaurant, ...props }) => ({
    ...props,
    restaurantId: restaurant.id,
  })),
)(PopBubble));
