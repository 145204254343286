import { readableFontColor } from '../../../utils/colors';

export default theme => ({
  bottom: {
    backgroundColor: theme.palette.secondary.main,
    borderTop: theme.palette.primary.main !== theme.palette.secondary.main ? `2px solid ${theme.palette.primary.main}` : null,
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  bottomButton: {
    '& svg': {
      fontSize: '1.5em',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    color: readableFontColor(theme.palette.secondary.main),
    textTransform: 'none',
  },
  bottomFixed: {
    [theme.breakpoints.down('md')]: {
      position: 'static',
      width: 'auto',
    },
    bottom: 0,
    left: 0,
    position: 'fixed',
    width: '100%',
    zIndex: 6,
  },
});
