import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import { compose } from '@shakacode/recompose';
import { Button, Icon } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';

import { Camera, ChevronLeft } from '@popmenu/web-icons';
import { classNames, withStyles } from '../../../utils/withStyles';
import { themeShape, withTheme } from '../../../utils/withTheme';
import menuItemDetailsGalleryStyles from './styles';

import CustomImg from '../../../shared/CustomImg';
import LegacyMenuItemDetailsInfo from '../LegacyMenuItemDetailsInfo';
import MenuItemCloseButton from '../MenuItemCloseButton';
import PhotoGallery from '../../photos/PhotoGallery';
import { AH, AHLevelProvider } from '../../shared/AccessibleHeading';

const MenuItemDetailsGallery = ({ classes, closeMenuItemModal, menuItem, modernLayout, theme }) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const swipeableProps = useSwipeable({
    onSwipedLeft: () => setPhotoIndex(photoIndex <= 0 ? menuItem.photos.length - 1 : photoIndex - 1),
    onSwipedRight: () => setPhotoIndex(photoIndex >= menuItem.photos.length - 1 ? 0 : photoIndex + 1),
  });
  const photo = menuItem.photos[photoIndex];
  if (!photo) {
    return null;
  }
  return (
    <section>
      <AH typography className={classes.galleryHeading} variant="h3">
        <FormattedMessage
          id="menu_item_details_gallery.photo_gallery"
          defaultMessage="Photo Gallery"
        />
      </AH>
      <AHLevelProvider>
        <div className={classNames([classes.galleryContainer, 'gallery-container-modern'])}>
          <div className={classes.galleryPhotoContainer}>
            <div
              className={classes.galleryPhotoControlsContainer}
              data-cy={'menu_item_details_gallery_container'}
              {...swipeableProps}
            >
              <CustomImg
                key={photo.id}
                alt={menuItem.name}
                className={classNames(
                  classes.galleryPhoto,
                  (photo.photoWidth && photo.photoHeight && photo.photoWidth < photo.photoHeight) ? classes.galleryPhotoVertical : classes.galleryPhotoHorizontal,
                )}
                onLoad={() => setImgLoaded(true)}
                src={photo.photoUrl}
                usePreviewAsPlaceholder
                waitForScrolled
              />
            </div>
            {theme.useLegacyDishLayout && (
              <LegacyMenuItemDetailsInfo
                closeMenuItemModal={closeMenuItemModal}
                menuItem={menuItem}
                withinGallery
              />
            )}
            {
              // display slider icons if modern layout is enabled
              modernLayout && menuItem.photos.length > 1 && (
                <div className={classes.modernGalleryPhotoControls}>
                  <Icon size="extra-large" icon={ChevronLeft} onClick={() => setPhotoIndex(photoIndex <= 0 ? menuItem.photos.length - 1 : photoIndex - 1)} />
                  <Icon size="extra-large" icon={ChevronLeft} onClick={() => setPhotoIndex(photoIndex >= menuItem.photos.length - 1 ? 0 : photoIndex + 1)} />
                </div>
              )
            }
            {
              !modernLayout && (
                <React.Fragment>
                  <MenuItemCloseButton
                    className={classNames(
                      classes.closeButtonAbsolute,
                      menuItem.photos.length === 0 ? classes.closeButtonAbsoluteWithoutPhotos : null,
                    )}
                    closeMenuItemModal={closeMenuItemModal}
                    menuItem={menuItem}
                  />
                  {imgLoaded && (
                    <PhotoGallery
                      alt={menuItem.name}
                      aria-label={`Open Photo Gallery (${menuItem.photos.length} ${menuItem.photos.length === 1 ? 'photo' : 'photos'})`}
                      ButtonProps={{
                        children: `${menuItem.photos.length} ${menuItem.photos.length === 1 ? 'photo' : 'photos'}`,
                      }}
                      className={classes.fullscreenButton}
                      eventableId={menuItem.id}
                      eventableType="MenuItem"
                      icon={Camera}
                      photos={menuItem.photos.map(galleryPhoto => ({
                        alt: menuItem.name,
                        photoUrl: galleryPhoto.photoUrl,
                        thumbnailUrl: galleryPhoto.thumbnailUrl,
                      }))}
                    />
                  )}
                </React.Fragment>
              )
            }
            {!theme.useLegacyDishLayout && !modernLayout && menuItem.photos.length > 1 && (
              <div className={classes.galleryDotsContainer}>
                {menuItem.photos.map((menuItemPhoto, index) => (
                  <Button
                    key={menuItemPhoto.id}
                    aria-label={`Photo ${index + 1}`}
                    className={classNames(
                      classes.galleryDot,
                      index === photoIndex ? classes.galleryDotActive : null,
                    )}
                    onClick={() => setPhotoIndex(index)}
                    variant="contained"
                  >
                    {' '}
                  </Button>
                ))}
              </div>
            )}
          </div>
          {theme.useLegacyDishLayout && (
            <div className={classes.galleryContainerBackground} />
          )}
        </div>
      </AHLevelProvider>
    </section>
  );
};

MenuItemDetailsGallery.defaultProps = {
  closeMenuItemModal: null,
  modernLayout: false,
};

MenuItemDetailsGallery.propTypes = {
  classes: PropTypes.object.isRequired,
  closeMenuItemModal: PropTypes.func,
  menuItem: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    photos: PropTypes.arrayOf(PropTypes.shape({
      photoHeight: PropTypes.number,
      photoUrl: PropTypes.string,
      photoWidth: PropTypes.number,
    })),
  }).isRequired,
  modernLayout: PropTypes.bool,
  theme: themeShape.isRequired,
};

export default compose(
  withStyles(menuItemDetailsGalleryStyles),
  withTheme,
)(MenuItemDetailsGallery);
