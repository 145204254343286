import React from 'react';
import { compose } from '@shakacode/recompose';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '../../../utils/withStyles';
import PopBubble from '../../pops/PopBubble';
import ShareButton from '../ShareButton';
import styles from './styles';
import SinglePopTypeBubble from '../../pops/PopBubble/SinglePopTypeBubble';

const ModernEngagementIcons = ({ classes, compact, menuItem, restaurant }) => {
  const bubbleProps = {
    likedItPopsCount: menuItem.likedItPopsCount,
    lovedItPopsCount: menuItem.lovedItPopsCount,
    menuItemId: menuItem.menuItemId || menuItem.id,
    menuItemName: menuItem.menuItemName || menuItem.name,
    poppableId: menuItem.poppableId || menuItem.dishId,
    poppableType: 'Dish',
    popsCount: menuItem.popsCount,
    wannaTryPopsCount: menuItem.wannaTryPopsCount,
  };
  return (
    <React.Fragment>
      {!compact && (
        <div className={classes.defaultEngagementIcons}>
          <SinglePopTypeBubble modernLayout popType="loved_it" {...bubbleProps} />
          <ShareButton
            modernLayout
            eventableId={menuItem.id}
            eventableType="MenuItem"
            photoUrl={menuItem.photos.length > 0 ? menuItem.photos[0].photoUrl : null}
            quote={`Check out ${menuItem.name}: ${restaurant.popmenuUrl}${menuItem.url}`}
            url={`${restaurant.popmenuUrl}${menuItem.url}`}
          >
            <FormattedMessage id="forms.share" defaultMessage="Share" />
          </ShareButton>
          <SinglePopTypeBubble modernLayout popType="wanna_try" {...bubbleProps} />
        </div>
      )}
      {compact && (
        <PopBubble
          disableLikes
          wannaTryIcon={'modern_wanna_try'}
          {...bubbleProps}
        />
      )}
    </React.Fragment>
  );
};

ModernEngagementIcons.defaultProps = {
  compact: false,
};

ModernEngagementIcons.propTypes = {
  classes: PropTypes.object.isRequired,
  compact: PropTypes.bool,
  menuItem: PropTypes.object.isRequired,
  restaurant: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
)(ModernEngagementIcons);
