import type { CurrentSession } from '~/shared/CurrentSessionProvider';

export const reviewFromSession = (session: CurrentSession, dishId: number) => {
  const reviews = (session.user?.reviews ?? []).concat(session.reviews ?? []);
  return reviews.find(review => (
    review.dishableType === 'Dish' && review.dishableId === dishId
  ));
};

export const reviewUpvoteFromSession = (session: CurrentSession, reviewId: number) => {
  const upvotes = (session.user?.reviewUpvotes ?? []).concat(session.reviewUpvotes ?? []);
  return upvotes.find(upvote => (
    upvote.reviewId === reviewId
  ));
};
