import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '~/lazy_apollo/client';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { Link, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { Button, Icon } from '@popmenu/common-ui';
import { ArrowLeft, Send, X as XIcon } from '@popmenu/web-icons';
import { createPageView } from '~/utils/eventable';
import { themeShape } from '../../../utils/withTheme';
import { closeAddToCartModal, openEditMenuItemCartModalFromCustomPage, openAddToCartModal } from '../../../shared/DishActions';
import { openReviewSectionModal } from '../../../shared/ModalActions';
import { withIntl } from '../../../utils/withIntl';
import { withRestaurant } from '../../../utils/withRestaurant';
import usePrevious from '../../../utils/usePrevious';
import { classNames, withStyles } from '../../../utils/withStyles';
import menuItemDetailsStyles from './styles';
import { setMenuItemCartLocationId, setMenuItemCartIsScheduled } from '../../../shared/MenuItemCartActions';
import { withWindowSizeContext } from '../../../shared/WindowSizeProvider';
import Grid from '../../../shared/Grid';
import Loading from '../../../shared/Loading';
import MenuItemDetailsBottom from '../MenuItemDetailsBottom';
import MenuItemDetailsGallery from '../MenuItemDetailsGallery';
import MenuItemDetailsInfo from '../MenuItemDetailsInfo';
import MenuItemModernDetailsInfo from '../MenuItemModernDetailsInfo';
import MenuItemDetailsSkeleton from '../MenuItemDetailsSkeleton';
import MenuItemJSONLD from '../MenuItemJSONLD';
import ShareButton from '../../shared/ShareButton';
import restaurantLocationOrderingAvailabilityQuery from '../../../libs/gql/queries/locations/restaurantLocationOrderingAvailability.gql';

// This is the contents of the Modal for the menu item.
// It's also the contents of a set of pages for menu items that are used for search optimization
const MenuItemDetails = ({
  classes,
  closeMenuItemModal,
  isModal,
  loading,
  menuEnabled,
  menuOrderingUrl,
  menuItem,
  menuItemId,
  openMenuItemModal,
  restaurant,
  scheduledAt,
  selectedMenuItem,
  showAddToCartButton,
  theme,
  windowSize,
  onSubmitComplete,
  ...props
}) => {
  const history = useHistory();
  const locationParam = history?.location?.hash?.split(/[?|&]/)?.find(section => section.includes('location'));
  const [handledVisit, setHandledVisit] = useState(false);
  const prevMenuItem = usePrevious(menuItem);
  const prevMenuItemId = usePrevious(menuItem?.id);
  const modernLayout = theme.dishLayout === 'modern_dish_layout';
  const bLayout = theme.dishDetailImageAlignment === 'left' && windowSize.innerWidth >= 1200;
  const hasPhotos = menuItem?.photos?.length > 0;

  const handleVisit = useCallback(() => {
    if (handledVisit || !menuItem) {
      return;
    }
    setHandledVisit(true);

    // Attach to snowplow page tracking
    createPageView([
      {
        data: {
          id: menuItem.id,
        },
        schema: 'iglu:com.popmenu/menu_item/jsonschema/1-0-0',
      },
      {
        data: {
          id: get(menuItem, 'dish.id', null),
        },
        schema: 'iglu:com.popmenu/dish/jsonschema/1-0-0',
      },
    ]);

    if (isModal) {
      let urlParams = `#menu=${menuItem?.section?.menu?.slug}&item=${menuItem?.slug}`;
      if (locationParam) {
        urlParams += `&${locationParam}`;
      }
      window.history.pushState(null, null, urlParams);
    }
  }, [isModal, handledVisit, menuItem, locationParam]);

  const closeModal = useCallback(() => {
    if (isModal) {
      if (locationParam) { // if the location param is present, the component is being rendered in the online ordering section
        window.history.pushState(null, null, `#menu=?${locationParam}`);
      } else { // it's being rendered in the menu section
        window.history.pushState(null, null, `#menu=?${menuItem?.section?.menu?.location?.slug}`);
      }
    }
    if (closeMenuItemModal) {
      closeMenuItemModal();
    }
  }, [closeMenuItemModal, isModal, menuItem?.section?.menu?.location?.slug, locationParam]);

  const returnToMenu = useCallback(() => {
    history.push(`/#menu=?${menuItem?.section?.menu?.location?.slug}`);
  }, [history, menuItem?.section?.menu?.location?.slug]);

  useEffect(() => {
    if (isModal) {
      window.addEventListener('popstate', closeModal);
    }
    if (prevMenuItem && menuItem && prevMenuItemId !== menuItem?.id) {
      setHandledVisit(false);
    }
    handleVisit();
    return isModal ? window.removeEventListener('popstate', closeModal) : () => {};
  }, [closeModal, prevMenuItem, prevMenuItemId, isModal, menuItem, handleVisit, setHandledVisit]);

  // Prepare lazy query to pull location data down
  const [
    locationQuery,
    { data: locationData },
  ] = useLazyQuery(restaurantLocationOrderingAvailabilityQuery, {
    variables: {
      locationId: menuItem?.menu?.locationId,
      restaurantId: menuItem?.restaurantId,
    },
  });

  // Pull location data down on effect
  useEffect(() => {
    if (!menuItem) {
      return;
    }

    locationQuery();
  }, [locationQuery, menuItem]);

  const openModalInCart = useCallback(() => {
    /**
     * In instances where the cart location either has only ASAP pickup or only Schedule for
     * Later available, as defined in the following if statements, the redux cart state shall
     * be updated to reflect the necessary alteration.
     */
    const orderingAvailable = locationData?.restaurantLocationOrderingAvailability?.isOrderingAvailable;
    const orderingScheduleEnabled = locationData?.restaurantLocationOrderingAvailability?.isOrderingScheduleEnabled;
    if (!orderingAvailable && !orderingScheduleEnabled) {
      props.setMenuItemCartIsScheduled(true);
    }

    if (orderingAvailable && !orderingScheduleEnabled) {
      props.setMenuItemCartIsScheduled(false);
    }

    props.setMenuItemCartLocationId(locationData?.restaurantLocationOrderingAvailability?.id);
    const isOrderingEventOrderingPage = /\/orders\/[a-zA-Z0-9-]+\/order/.test(window.location.pathname);
    if (!modernLayout && isOrderingEventOrderingPage) {
      props.openAddToCartModal(menuItem.id, menuItem.menu.id);
    } else {
      props.openEditMenuItemCartModalFromCustomPage(menuItem.id, menuItem.menu.id);
    }

    if (modernLayout && showAddToCartButton) {
      openMenuItemModal(menuItem.id, true, menuEnabled, null);
    }
  }, [locationData, menuItem, props, menuEnabled, modernLayout, openMenuItemModal, showAddToCartButton]);

  const openReviewModal = () => {
    props.openReviewSectionModal(menuItem?.section?.menu?.location?.id, menuItem?.id);
  };

  const openMenuItemModalWithoutMenuItemCart = useCallback(() => {
    openMenuItemModal(menuItem.id, true, menuEnabled, null);
  }, [menuEnabled, menuItem, openMenuItemModal]);

  const closeModernModal = useCallback(() => {
    closeModal();
    props.closeAddToCartModal();
  }, [props, closeModal]);

  const openModernMenuCartModal = useCallback((selectedMenuItemId, selectedMenuId) => {
    props.openAddToCartModal(selectedMenuItemId, selectedMenuId);
  }, [props]);

  const detailsInfoProps = () => {
    const defaultProps = {
      closeMenuItemModal: isModal ? closeModal : null,
      isModal,
      menuEnabled,
      menuItem,
      menuOrderingUrl,
      onSubmitComplete,
      openMenuItemModalWithoutMenuItemCart,
      openModalInCart,
      openReviewModal,
      scheduledAt,
      selectedMenuItem,
      showAddToCartButton,
    };

    if (modernLayout) {
      defaultProps.bLayout = bLayout;
      defaultProps.modernLayout = modernLayout;
      defaultProps.loading = loading;
      defaultProps.openModernMenuCartModal = openModernMenuCartModal;
      defaultProps.closeMenuItemModal = isModal ? closeModernModal : returnToMenu;
    }

    return defaultProps;
  };

  if (!modernLayout && (loading || !menuItem)) {
    if (theme.useLegacyDishLayout) {
      return <Loading size="fullscreen" />;
    }
    return <MenuItemDetailsSkeleton isModal={isModal} menuItemId={menuItemId} />;
  }

  return (
    <article
      className={classNames(
        classes.container,
        isModal ? classes.containerModal : null,
        modernLayout && classes.modernDishLayoutContainer,
        modernLayout && !bLayout && classes.modernDishLayoutContainerLayoutA,
        modernLayout && !hasPhotos && classes.noPhotos,
        modernLayout && showAddToCartButton && classes.modernDishLayoutButtonFooterCart,
        modernLayout && !showAddToCartButton && classes.modernDishLayoutButtonFooter,
      )}
    >
      {
        !modernLayout && (
          <div className={classes.mobileTop}>
            {isModal ? (
              <Button
                aria-label="Back To Menu"
                className={classes.mobileCloseButton}
                startIcon={<Icon icon={XIcon} />}
                onClick={closeModal}
                size="large"
                variant="text"
              />
            ) : (
              <Button
                aria-label="Back To Menu"
                className={classes.mobileCloseButton}
                component={Link}
                startIcon={<Icon icon={ArrowLeft} />}
                size="large"
                to={menuItem.section.menu.menuPageUrl}
                variant="text"
              />
            )}
            {hasPhotos && (
              <ShareButton
                aria-label="Share this item"
                data-cy="share_popup"
                className={classes.mobileShareButton}
                eventableId={menuItem.id}
                eventableType="MenuItem"
                photoUrl={menuItem.photos.length > 0 ? menuItem.photos[0].photoUrl : null}
                quote={`Check out ${menuItem.name}: ${menuItem.shortLinkUrl}`}
                size="lg"
                url={menuItem.shortLinkUrl}
                variant="text"
              >
                <Icon icon={Send} />
              </ShareButton>
            )}
          </div>
        )
      }
      <Grid
        alignItems="stretch"
        className={classNames(
          classes.containerTop,
          isModal ? classes.containerTopModal : null,
          'details-container-top-modal',
        )}
        container
        justify={hasPhotos ? 'flex-start' : 'center'}
      >
        <Grid item lg={hasPhotos ? 4 : 6} xs={12}>
          {
            React.createElement(modernLayout ? MenuItemModernDetailsInfo : MenuItemDetailsInfo, detailsInfoProps())
          }
        </Grid>
        {hasPhotos && (
          <Grid item lg={8} xs={12}>
            <MenuItemDetailsGallery
              closeMenuItemModal={isModal ? closeModal : null}
              menuItem={menuItem}
              modernLayout={modernLayout}
            />
          </Grid>
        )}
      </Grid>
      {!modernLayout && !theme.useLegacyDishLayout && (
        <MenuItemDetailsBottom
          isModal={isModal}
          menuItem={menuItem}
          openMenuItemModal={openMenuItemModal}
        />
      )}
      {menuItem && (
        <MenuItemJSONLD menuItem={menuItem} />
      )}
    </article>
  );
};

MenuItemDetails.defaultProps = {
  closeMenuItemModal: null,
  isModal: false,
  menuEnabled: true,
  menuItem: null,
  menuItemId: null,
  onSubmitComplete: null,
  openMenuItemModal: null,
  scheduledAt: null,
  showAddToCartButton: false,
};

MenuItemDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  closeAddToCartModal: PropTypes.func.isRequired,
  closeMenuItemModal: PropTypes.func,
  isModal: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  menuEnabled: PropTypes.bool,
  menuItem: PropTypes.shape({
    currency: PropTypes.string,
    description: PropTypes.string,
    dish: PropTypes.shape({
      dishTags: PropTypes.array,
    }),
    dishId: PropTypes.number,
    extras: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.number,
    })),
    id: PropTypes.number,
    isPoppable: PropTypes.bool,
    itemBackgroundColor: PropTypes.string,
    likedItPopsCount: PropTypes.number,
    lovedItPopsCount: PropTypes.number,
    menuBackgroundColor: PropTypes.string,
    name: PropTypes.string,
    photos: PropTypes.arrayOf(PropTypes.shape({
      approved: PropTypes.bool,
      id: PropTypes.number,
      photoHeight: PropTypes.number,
      photoUrl: PropTypes.string,
      photoWidth: PropTypes.number,
      shareUrl: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      userId: PropTypes.number,
    })),
    pops: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      popType: PropTypes.string,
      user: PropTypes.shape({
        displayName: PropTypes.string,
        thumbnailUrl: PropTypes.string,
      }),
    })),
    popsCount: PropTypes.number,
    price: PropTypes.number,
    restaurant: PropTypes.shape({
      allowAnonymousPops: PropTypes.bool,
      allowAnonymousReviews: PropTypes.bool,
      customDishTabContent: PropTypes.string,
      customDishTabName: PropTypes.string,
      id: PropTypes.number,
      logoUrl: PropTypes.string,
      name: PropTypes.string,
    }),
    reviews: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.string,
      id: PropTypes.number,
      user: PropTypes.shape({
        displayName: PropTypes.string,
        thumbnailUrl: PropTypes.string,
      }),
    })),
    reviewsCount: PropTypes.number,
    section: PropTypes.shape({
      menu: PropTypes.shape({
        allowReservation: PropTypes.bool,
        location: PropTypes.shape({
          allowReservations: PropTypes.bool,
          openTableId: PropTypes.string,
          phone: PropTypes.string,
          yelpFriendlyId: PropTypes.string,
        }),
        menuPageUrl: PropTypes.string,
        name: PropTypes.string,
      }),
      name: PropTypes.string,
    }),
    sectionBackgroundColor: PropTypes.string,
    shortLinkUrl: PropTypes.string,
    sizes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.number,
    })),
    slug: PropTypes.string,
    url: PropTypes.string,
    wannaTryPopsCount: PropTypes.number,
  }),
  menuItemId: PropTypes.number,
  onSubmitComplete: PropTypes.func,
  openAddToCartModal: PropTypes.func.isRequired,
  openEditMenuItemCartModalFromCustomPage: PropTypes.func.isRequired,
  openMenuItemModal: PropTypes.func,
  openReviewSectionModal: PropTypes.func.isRequired,
  restaurantId: PropTypes.number.isRequired,
  scheduledAt: PropTypes.string,
  showAddToCartButton: PropTypes.bool,
  t: PropTypes.func.isRequired,
  theme: themeShape.isRequired,
};

export default compose(
  withRestaurant,
  mapProps(({ restaurant, ...props }) => ({
    ...props,
    restaurant,
    restaurantId: restaurant.id,
    theme: restaurant.theme,
  })),
  withIntl,
  withStyles(menuItemDetailsStyles),
  withWindowSizeContext,
  connect(state => ({
    showAddToCartButton: state.dishes.showAddToCartButton,
  }), {
    closeAddToCartModal,
    openAddToCartModal,
    openEditMenuItemCartModalFromCustomPage,
    openReviewSectionModal,
    setMenuItemCartIsScheduled,
    setMenuItemCartLocationId,
  }),
)(MenuItemDetails);
