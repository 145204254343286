import { readableFontColor } from '../../../utils/colors';

export default theme => ({
  closeButtonStatic: {
    [theme.breakpoints.down('md')]: {
      display: 'none !important',
    },
    color: 'white !important',
    display: 'none',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    position: 'relative',
    textTransform: 'none',
  },
  closeButtonStaticWithoutPhotos: {
    [theme.breakpoints.down('md')]: {
      display: 'none !important',
    },
    display: 'inline-block !important',
  },
  dishDescription: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    color: 'white',
    marginTop: theme.spacing(2),
    textAlign: 'left',
  },
  dishName: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    color: 'white !important',
    fontSize: '2em',
    fontStyle: 'italic',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  dishPrice: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0 16px 16px 0',
    color: readableFontColor(theme.palette.primary.main),
    fontSize: '1em',
    fontWeight: 'bold',
    padding: theme.spacing(1, 2),
    textAlign: 'left',
  },
  dishPriceContainer: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    backgroundColor: '#1D1D1D',
    borderRadius: '16px',
    display: 'flex',
    marginBottom: theme.spacing(2),
    width: 'fit-content',
  },
  dishTag: {
    borderRadius: '50%',
    color: 'white',
    display: 'inline-block',
    fontFamily: '"Roboto Mono", monospace',
    fontSize: '0.8em',
    fontWeight: 'bold',
    height: theme.spacing(3),
    lineHeight: `${theme.spacing(3)}px`,
    margin: theme.spacing(0.25, 0.5, 0, 0),
    overflow: 'hidden',
    textAlign: 'center',
    width: theme.spacing(3),
  },
  dishTagImg: {
    borderRadius: '0 !important',
    objectFit: 'contain',
  },
  dishTagsContainer: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
  },
  extrasContainer: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    marginTop: theme.spacing(2),
    textAlign: 'left',
  },
  extrasTag: {
    color: 'white',
    display: 'inline-block',
    margin: theme.spacing(0.25, 0.5, 0, 0),
  },
  legacyInfoContainer: {
    textAlign: 'left',
  },
  legacyInfoContainerWithinGallery: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    background: 'none',
    bottom: 0,
    left: 0,
    padding: theme.spacing(4),
    position: 'absolute',
    zIndex: 2,
  },
  legacyInfoContainerWithinSidebar: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      textAlign: 'center',
    },
    background: 'black',
    display: 'none',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  legacyInfoContainerWithoutPhotos: {
    display: 'block !important',
  },
  menuName: {
    '&:last-child': {
      borderRadius: '16px',
    },
    borderRadius: '16px 0 0 16px',
    color: 'white',
    fontSize: '1em',
    fontWeight: 'bold',
    padding: theme.spacing(1, 2),
    textTransform: 'uppercase',
  },
});
