import React, { type CSSProperties } from 'react';
import type { ClassNameMap } from '@material-ui/core/styles/withStyles';
import type { MenuItemExtra, MenuItemExtraGroup, MenuItemSharedExtraGroup, MenuItemSize } from '~/libs/gql/types';
import { formatCurrency } from '../../utils/currency';
import { classNames } from '../../utils/withStyles';
import { useFeatureFlags } from '../../utils/featureFlagsContext';

const ellipsis = '...';
const colon = ':';

interface DishExtrasProps {
  classes?: ClassNameMap;
  component?: React.ElementType;
  currency?: string | null;
  displayExtraGroups?: boolean;
  extraGroups: MenuItemExtraGroup[];
  extras: MenuItemExtra[];
  sharedExtraGroups: MenuItemSharedExtraGroup[];
  showCurrencySymbol: boolean;
  showPrice?: boolean;
  sizes: MenuItemSize[];
  style?: CSSProperties | null;
  textAlign?: 'left' | 'center';
  truncatePriceOptions?: boolean;
}

const DishExtras = ({
  classes = {},
  component = 'div',
  currency = null,
  displayExtraGroups = false,
  extraGroups,
  extras,
  sharedExtraGroups,
  showCurrencySymbol,
  showPrice = true,
  sizes,
  style = null,
  textAlign = 'left',
  truncatePriceOptions = false,
}: DishExtrasProps) => {
  const { isFeatureActive } = useFeatureFlags();
  const isNestedModifiersEnabled = isFeatureActive('isNestedModifiersEnabled');
  let extraElements: React.JSX.Element[] = [];

  const renderPrice = (
    price: Optional<number>,
    { includePlus = true } = {},
  ) => (price != null && showPrice ?
    <span>{includePlus ? '+' : ''}{formatCurrency(price, currency, { showSymbol: showCurrencySymbol })}</span> :
    null);

  // Sizes
  const sizesWithNames = sizes.filter(size => size.name);

  sizesWithNames.forEach((size) => {
    const price = renderPrice(size.price, { includePlus: false });
    extraElements.push((
      <span
        key={`size-${size.id}`}
        className={classNames(classes.extrasTag || 'extra dish-size', 'pm-dish-extras-with-size-name')}
      >
        <span>{size.name}</span> {price}
      </span>
    ));
  });

  if (truncatePriceOptions && sizesWithNames.length > 2) {
    extraElements = extraElements.slice(0, 2);
    extraElements.push((<span key={'truncatedSizes-ellipse'} className={classNames(classes.extrasTag || 'extra dish-size', 'pm-dish-extras-truncated')}>{ellipsis}</span>));
  }

  // Extras
  if (!isNestedModifiersEnabled) {
    extras.filter(extra => extra.name)
      .forEach((extra) => {
        extraElements.push((
          <span
            key={`extra-${extra.id}`}
            data-testid="dish-extras-container"
            className={classNames(classes.extrasTag || 'extra dish-extra', 'pm-dish-extras')}
          >
            <span>{extra.name}</span> {renderPrice(extra.price)}
          </span>
        ));
      });
  }

  // Extra groups
  const extraGroupElements: React.JSX.Element[] = [];
  if (displayExtraGroups && !isNestedModifiersEnabled) {
    extraGroups.filter(extraGroup => extraGroup.extras.length > 0).forEach((extraGroup) => {
      extraGroupElements.push((
        <div key={`extra-group-${extraGroup.id}`} className={classNames(classes.extraGroup || 'dish-extra-group', 'pm-dish-extras-group-name-parent')}>
          <span className={classNames(classes.extrasTag || 'extra dish-extra', classes.extraGroupName || 'extra-group-name', 'pm-dish-extras-group-name')}>
            {extraGroup.name}{colon}
          </span>
          {extraGroup.extras.map(extra => (
            <span
              key={extra.id}
              className={classNames(classes.extrasTag || 'extra dish-extra', 'pm-dish-extras-extra-group')}
            >
              <span>{extra.name}</span> {renderPrice(extra.price)}
            </span>
          ))}
        </div>
      ));
    });
    sharedExtraGroups.forEach((sharedExtraGroup) => {
      if (!sharedExtraGroup.sharedExtraGroup || sharedExtraGroup.sharedExtraGroup.extras.length === 0) {
        return;
      }

      extraGroupElements.push((
        <div key={`shared-extra-group-${sharedExtraGroup.sharedExtraGroup.id}`} className={classNames(classes.extraGroup || 'dish-extra-group', 'pm-dish-extras-shared-extra-group-parent')}>
          <span className={classNames(classes.extrasTag || 'extra dish-extra', classes.extraGroupName || 'extra-group-name', 'pm-dish-extras-shared-extra-group-name')}>
            {sharedExtraGroup.sharedExtraGroup.name}{colon}
          </span>
          {sharedExtraGroup.sharedExtraGroup.extras.map(extra => (
            <span
              key={extra.id}
              className={classNames(classes.extrasTag || 'extra dish-extra', 'pm-dish-extras-shared-extra-group')}
            >
              <span>{extra.name}</span> {renderPrice(extra.price)}
            </span>
          ))}
        </div>
      ));
    });
  }
  if (extraElements.length === 0 && extraGroupElements.length === 0) {
    return null;
  }

  const textAlignStyle = textAlign === 'center' ? { display: 'flex', justifyContent: 'center' } : null;
  return (
    <React.Fragment>
      {extraElements.length > 0 && React.createElement(
        component,
        {
          className: classes.extrasContainer || 'dish-extras',
          style: style || textAlignStyle,
        },
        extraElements,
      )}
      {extraGroupElements.length > 0 && React.createElement(
        component,
        {
          className: classes.extrasContainer || 'dish-extras',
          style: style || textAlignStyle,
        },
        extraGroupElements,
      )}
    </React.Fragment>
  );
};

export default DishExtras;
