export default theme => ({
  dishDescription: {
    textAlign: 'center',
  },
  dishTagsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 0,
  },
  hidden: {
    display: 'none',
  },
  leaveReviewButton: {
    marginBottom: theme.spacing(1),
    textDecoration: 'underline',
  },
  modernDetailsContainer: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
    },
    paddingLeft: 0,
  },
  modernReserveContainer: {
    '& .pm-reservation-widget-yelp iframe': {
      height: '110px',
      width: '100%',
    },
  },
  modernReserveDivider: {
    '& + span': {
      background: 'white',
      height: theme.spacing(3),
      left: 'calc(50% - 36px)',
      position: 'absolute',
      textTransform: 'uppercase',
      top: '-8px',
      width: '72px',
    },
    margin: `${theme.spacing(3)}px 0`,
  },
  modernReserveHeading: {
    marginBottom: theme.spacing(2),
  },
  modernTab: {
    flex: '1 1 calc(100% / 3)',
    minWidth: 'fit-content',
  },
  modernTabContentWrapper: props => ({
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      minHeight: props.hasPhoto ? 'calc(90vh - 360px)' : 'calc(90vh - 180px)',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 0,
    },
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    minHeight: props.hasPhoto && !props.isLayoutB ? 'calc(90vh - 240px)' : props.theme.dishDetailTab === 'empty' ? 'calc(90vh - 260px)' : 'calc(90vh - 240px)',
  }),

  modernTabContentWrapperWithTabs: props => ({
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      minHeight: props.hasPhoto ? 'calc(90vh - 360px)' : 'calc(90vh - 180px)',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 0,
    },
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    minHeight: props.hasPhoto ? 'calc(90vh - 340px)' : props.isLayoutB ? 'calc(90vh - 350px)' : 'calc(90vh - 340px)',
  }),
  modernTabsContainer: {
    '& span.MuiTabs-indicator': {
      width: 'calc(100% / 3)',
    },
  },
  reservationPopover: {
    '& #yelp-reservation-widget': {
      boxSizing: 'content-box',
      height: '110px',
      maxWidth: '100vw',
      padding: '10px',
      width: '500px',
    },
    '& .MuiPaper-root': {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '-8px',
      },
    },
    '& .pm-reservation-widget-open-table': {
      minHeight: '320px',
      minWidth: '255px',
    },
  },
  reservationPopoverHidden: { // used only for resy to 'render' the button but not show it
    '& .pm-reservation-widget-resy': {
      height: '1px',
      overflow: 'hidden',
      width: '1px',
    },
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    height: '1px',
    overflow: 'hidden',
    width: '1px',
  },
  reviewContent: {
    fontSize: '1em',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  reviewHeading: {
    color: theme.consumer.primaryColorOnWhite,
    fontSize: '1em',
    fontWeight: 'bold',
    minHeight: theme.spacing(3),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});
