import { Bookmark, Heart, ThumbsDown, ThumbsUp } from '@popmenu/web-icons';

import type { CurrentSession } from '~/shared/CurrentSessionProvider';

export const POP_ICONS = {
  disliked_it: ThumbsDown,
  liked_it: ThumbsUp,
  loved_it: Heart,
  modern_wanna_try: Bookmark,
} as const;
export type PopIconName = keyof typeof POP_ICONS;

export const POP_TYPES = [
  'loved_it',
  'liked_it',
  'disliked_it',
  'wanna_try',
] as const;
export type PopType = typeof POP_TYPES[number];
export type Pop = { popType: PopType };

export const calendarEventPopFromSession = (session: CurrentSession, calendarEventId: number) => {
  const pops = (session.user?.calendarEventPops ?? []).concat(session.calendarEventPops ?? []);
  return pops.find(pop => pop.calendarEventId === calendarEventId);
};

export const popFromSession = (session: CurrentSession, dishId: number) => {
  const pops = (session.user?.pops ?? []).concat(session.pops ?? []);
  return pops.find(pop => pop.dishableType === 'Dish' && pop.dishableId === dishId);
};
