import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Typography } from '@popmenu/common-ui';

import { formatCurrency } from '../../../utils/currency';
import { nl2br } from '../../../utils/react';
import { themeShape, withTheme } from '../../../utils/withTheme';
import { classNames, withStyles } from '../../../utils/withStyles';
import legacyMenuItemDetailsInfoStyles from './styles';

import DishExtras from '../DishExtras';
import DishTags from '../DishTags';
import MenuItemCloseButton from '../MenuItemCloseButton';
import { AH } from '../../shared/AccessibleHeading';

const LegacyMenuItemDetailsInfo = ({
  classes,
  closeMenuItemModal,
  menuItem,
  theme,
  withinGallery,
}) => (
  <div
    className={classNames(
      classes.legacyInfoContainer,
      withinGallery ? classes.legacyInfoContainerWithinGallery : classes.legacyInfoContainerWithinSidebar,
      menuItem.photos.length === 0 && !withinGallery ? classes.legacyInfoContainerWithoutPhotos : null,
    )}
  >
    <MenuItemCloseButton
      className={classNames(
        classes.closeButtonStatic,
        menuItem.photos.length === 0 && !withinGallery ? classes.closeButtonStaticWithoutPhotos : null,
      )}
      closeMenuItemModal={closeMenuItemModal}
      menuItem={menuItem}
    />
    <div className={classes.dishPriceContainer}>
      <Typography className={classes.menuName}>
        {menuItem.section.name}
      </Typography>
      {Boolean(menuItem.priceCustomText || menuItem.price) && (
      <Typography data-testid="legacy-menu-item-details-info-dish-price" className={classes.dishPrice}>
        {menuItem.priceCustomText || formatCurrency(menuItem.price, menuItem.currency, { showSymbol: theme.showCurrencySymbol })}
      </Typography>
      )}
    </div>
    <AH typography className={classes.dishName} variant="h1">
      {menuItem.name}
    </AH>
    {menuItem.description && (
    <Typography className={classes.dishDescription}>
      {nl2br(menuItem.description)}
    </Typography>
    )}
    {theme.showPrice && (
    <DishExtras
      classes={classes}
      currency={menuItem.currency}
      displayExtraGroups={theme.showMenuItemExtraGroups}
      extraGroups={menuItem.extraGroups}
      extras={menuItem.extras}
      sharedExtraGroups={menuItem.sharedExtraGroups}
      showCurrencySymbol={theme.showCurrencySymbol}
      showPrice={theme.showPrice}
      sizes={menuItem.sizes}
    />
    )}
    <DishTags
      allSelectedDishTags={menuItem.dish.allSelectedDishTags}
      classes={classes}
    />
  </div>
);

LegacyMenuItemDetailsInfo.defaultProps = {
  closeMenuItemModal: null,
  withinGallery: false,
};

LegacyMenuItemDetailsInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  closeMenuItemModal: PropTypes.func,
  menuItem: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
    priceCustomText: PropTypes.string,
  }).isRequired,
  theme: themeShape.isRequired,
  withinGallery: PropTypes.bool,
};

export default compose(
  withTheme,
  withStyles(legacyMenuItemDetailsInfoStyles),
)(LegacyMenuItemDetailsInfo);
