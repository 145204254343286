import React from 'react';
import type { MenuItem, Restaurant } from '~/libs/gql/types';

import { withRestaurant } from '../../utils/withRestaurant';

import JSONLD from '../shared/JSONLD';

const POP_TYPES = {
  disliked_it: 2,
  liked_it: 4,
  loved_it: 5,
  wanna_try: 5,
};

type MenuItemJSONLDProps = {
  menuItem: MenuItem;
  restaurant: Restaurant;
};

class MenuItemJSONLD extends React.PureComponent<MenuItemJSONLDProps> {
  formatAggregateRating() {
    const { menuItem } = this.props;
    const ratingCount = menuItem.popsCount;
    const reviewCount = menuItem.reviewsCount;
    if (ratingCount === 0 || reviewCount === 0) {
      return null;
    }
    const ratingValue = (
      (menuItem.lovedItPopsCount * POP_TYPES.loved_it) +
      (menuItem.likedItPopsCount * POP_TYPES.liked_it) +
      (menuItem.wannaTryPopsCount * POP_TYPES.wanna_try)
    ) / menuItem.popsCount;
    return {
      '@type': 'AggregateRating',
      bestRating: 5,
      ratingCount: menuItem.popsCount,
      ratingValue,
      reviewCount,
      worstRating: 1,
    };
  }

  formatOffers() {
    const { menuItem, restaurant } = this.props;
    const { location } = menuItem.section?.menu || {};

    // Prevent returning offer without a price
    if (!restaurant.theme?.showPrice || !menuItem.price || !location) {
      return null;
    }

    // TODO: More "offers" for sizes/extras
    return [{
      '@type': 'Offer',
      areaServed: {
        '@type': 'Place',
        address: {
          addressLocality: location.city,
          addressRegion: location.state,
          email: location.email,
          postalCode: location.postalCode,
          streetAddress: location.streetAddress,
          telephone: location.phone,
        },
        name: restaurant.name,
      },
      price: menuItem.price,
      priceCurrency: menuItem.currency,
      url: `${restaurant.popmenuUrl}${menuItem.url}`, // Use location-specific URL for offer
    }];
  }

  render() {
    const { menuItem, restaurant } = this.props;
    const aggregateRating = this.formatAggregateRating();
    const offers = this.formatOffers();

    // Prevent including Product schema unless there is a price or ratings
    if (!aggregateRating && !offers) {
      return null;
    }
    return (
      <JSONLD>
        {{
          '@context': 'http://www.schema.org',
          '@type': 'Product',
          aggregateRating,
          category: menuItem.section?.name,
          description: menuItem.description,
          image: menuItem.photos && menuItem.photos[0] && menuItem.photos[0].shareUrl && {
            '@type': 'ImageObject',
            height: '630',
            url: menuItem.photos[0].shareUrl,
            width: '1200',
          },
          name: menuItem.name,
          offers,
          url: `${restaurant.popmenuUrl}${menuItem.url}`.split('?')[0], // Use page's canonical URL for product
        }}
      </JSONLD>
    );
  }
}

export default withRestaurant(MenuItemJSONLD);
