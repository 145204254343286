export default theme => ({
  bottomContainer: {
    bottom: '16px',
    position: 'absolute',
    width: '100%',
  },
  button: {
    height: '40px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  containerModal: {
    minHeight: '100vh',
    overflow: 'hidden',
    width: '100%',
  },
  description: {
    height: '1.5rem',
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  descriptionCenter: {
    height: '1.5rem',
    margin: '0 auto',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  tabs: {
    height: '35vh',
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
});
