import React from 'react';
import { Icon, Button, Typography } from '@popmenu/common-ui';
import { Calendar, Heart, Send, ThumbsUp } from '@popmenu/web-icons';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';

import { createEvent } from '~/utils/eventable';
import { popFromSession } from '../../../utils/pops';
import { toCamelCase } from '../../../utils/strings';
import { withIntl } from '../../../utils/withIntl';
import { withRestaurant } from '../../../utils/withRestaurant';
import { currentSessionShape, withCurrentSession } from '../../../shared/CurrentSessionProvider';
import { openVipV2Modal, setVipData } from '../../../shared/ModalActions';
import { classNames, withStyles } from '../../../utils/withStyles';
import popListStyles from './styles';
import updateMenuItemPopMutation from '../../../libs/gql/mutations/menus/updateMenuItemPopMutation.gql';

import Mutation from '../../../shared/Mutation';
import ShareButton from '../../shared/ShareButton';

import { AH } from '../../shared/AccessibleHeading';

const POP_TYPE_ICONS = {
  'calendar-o': Calendar,
  heart: Heart,
  'thumbs-up': ThumbsUp,
};

const PopList = (props) => {
  const { allowAnonymousPops, classes, currentSession, menuItem, restaurantId, t } = props;
  return (
    <React.Fragment>
      {menuItem.isPoppable && (
        <section className={classes.popListContainer}>
          <AH typography className={classes.popListHeading} variant="h4">
            <FormattedMessage
              id="menu_item.pop_list_heading"
              defaultMessage="Have you tried this item? Pop it!"
            />
          </AH>
          <Typography className={classes.popListSubheading}>
            <FormattedMessage
              id="menu_item.pop_list_subheading"
              defaultMessage="Help other diners know what to expect by sharing your experience."
            />
          </Typography>
          {['loved_it', 'liked_it', 'wanna_try'].map((popType) => {
            const userPop = popFromSession(currentSession, menuItem.dishId);
            const popped = !!(userPop && userPop.popType === popType);
            return (
              <Mutation
                key={popType}
                mutation={updateMenuItemPopMutation}
              >
                {(updateMenuItemPop, { loading }) => (
                  <Button
                    className={classNames(
                      classes.button,
                      classes[`button-${popType}`],
                      popped ? classes[`button-${popType}-popped`] : null,
                    )}
                    loading={loading}
                    startIcon={React.createElement(POP_TYPE_ICONS[t(`pops.pop_types.${popType}.icon`)])}
                    onClick={() => {
                      if (loading) {
                        return;
                      }
                      createEvent({
                        eventableId: menuItem.id,
                        eventableType: 'MenuItem',
                        eventType: 'pop_attempt_event',
                        restaurantId,
                      });
                      if (!allowAnonymousPops && !currentSession.user) {
                        props.setVipData({ isPopPath: true });
                        props.openVipV2Modal();
                        return;
                      }
                      updateMenuItemPop({
                        variables: {
                          menuItemId: menuItem.id,
                          popType: popped ? null : popType,
                        },
                      }).then(() => {
                        if (!currentSession.user) {
                          props.setVipData({ isPopPath: true });
                          props.openVipV2Modal();
                        }
                      });
                    }}
                    variant="text"
                  >
                    {t(`pops.pop_types.${popType}.new`)}
                  </Button>
                )}
              </Mutation>
            );
          })}
        </section>
      )}
      {menuItem.isPoppable && menuItem.popsCount > 0 && (
        <section className={classes.popListContainer}>
          <AH typography className={classes.popListHeading} variant="h4">
            {menuItem.popsCount === 1 ? '1 person has popped this item' : `${menuItem.popsCount} people have popped this item`}
          </AH>
          <div className={classes.popReactionsContainer}>
            <div className={classes.popCountsContainer}>
              {['loved_it', 'liked_it', 'wanna_try'].map((popType) => {
                const count = menuItem[`${toCamelCase(popType)}PopsCount`];
                if (!count) {
                  return null;
                }
                return (
                  <div
                    key={popType}
                    aria-label={`${count} "${t(`pops.pop_types.${popType}.popped`)}" ratings`}
                    className={classNames(
                      classes.popCountContainer,
                      classes[`popCountContainer-${popType}`],
                    )}
                  >
                    <Icon
                      className={classes.popCountIcon}
                      icon={POP_TYPE_ICONS[t(`pops.pop_types.${popType}.icon`)]}
                    />
                    <span className={classes.popCount}>{count}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
      <section className={classes.popListContainer}>
        <ShareButton
          className={classes.shareButton}
          eventableId={menuItem.id}
          eventableType="MenuItem"
          photoUrl={menuItem.photos.length > 0 ? menuItem.photos[0].photoUrl : null}
          quote={`Check out ${menuItem.name}: ${menuItem.shortLinkUrl}`}
          size="lg"
          url={menuItem.shortLinkUrl}
          variant="text"
        >
          <Icon icon={Send} /> &nbsp; <FormattedMessage id="menu_item.pop_list_heading" defaultMessage="Share this item" />
        </ShareButton>
      </section>
    </React.Fragment>
  );
};

PopList.propTypes = {
  allowAnonymousPops: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  currentSession: currentSessionShape.isRequired,
  menuItem: PropTypes.shape({
    dishId: PropTypes.number,
    id: PropTypes.number,
    isPoppable: PropTypes.bool,
    photos: PropTypes.arrayOf(PropTypes.shape({
      photoUrl: PropTypes.string,
    })),
    pops: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    popsCount: PropTypes.number,
    shortLinkUrl: PropTypes.string,
  }).isRequired,
  openVipV2Modal: PropTypes.func.isRequired,
  restaurantId: PropTypes.number.isRequired,
  setVipData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
  withCurrentSession,
  connect(() => ({}), {
    openVipV2Modal,
    setVipData,
  }),
  withRestaurant,
  mapProps(({ restaurant, ...props }) => ({
    ...props,
    allowAnonymousPops: restaurant.allowAnonymousPops,
    restaurantId: restaurant.id,
  })),
  withStyles(popListStyles),
)(PopList);
