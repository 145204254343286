import type { StyleRules, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => ({
  closeShareMenu: {
    marginLeft: theme.spacing(21),
    position: 'absolute',
    zIndex: 2,
  },
  menuItem: {
    '& svg': {
      height: theme.spacing(3),
      marginRight: theme.spacing(1),
      width: theme.spacing(3),
    },
    width: '100%',
  },
  modernShareButton: {
    boxShadow: '0px 0px 3px 0px #00000040 !important',
    color: '#000000',
    height: theme.spacing(5.5),
    marginLeft: '2px',
    width: theme.spacing(5.5),
  },
  modernSharePopover: {
    '& div': {
      boxShadow: 'none !important',
      overflow: 'visible',
    },
    '& ul': {
      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    },
  },
} as const satisfies StyleRules);
