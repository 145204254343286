import type { Theme } from '@material-ui/core/styles';
import type { StyleRules } from '@material-ui/styles';
import type { SharedPopBubbleProps } from './shared';

const bubbleSize = 6;

export default (theme: Theme) => ({
  container: props => ({
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
    float: props.poppableType === 'CalendarEvent' ? 'right' : undefined,
    height: theme.spacing(bubbleSize),
    margin: '0 auto',
    overflow: 'visible',
    position: 'relative',
    width: theme.spacing(bubbleSize),
  }),
  count: (props) => {
    const defaultStyle = {
      backgroundColor: '#EFEFEF',
      borderRadius: '0 0 16px 16px',
      color: '#000000',
      float: 'right',
      fontSize: '0.8750em',
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(0.25),
      paddingTop: theme.spacing(3.25),
      textAlign: 'center',
      width: theme.spacing(bubbleSize),
    } as const;

    if (props.disableLikes) {
      return ({
        '&:nth-child(2)': {
          borderRadius: '0 0 16px 0',
        },
        '&:nth-child(3), &:nth-child(4)': {
          borderRadius: '0 0 0 16px',
          paddingLeft: '17px',
          width: '65px',
        },
        ...defaultStyle,
      });
    }

    return ({
      '&:nth-child(2)': {
        borderRadius: '0 0 16px 0',
      },
      '&:nth-child(3), &:nth-child(4)': {
        borderRadius: 0,
      },
      '&:nth-child(5)': {
        borderRadius: '0 0 0 16px',
      },
      ...defaultStyle,
    });
  },
  countMain: {
    borderRadius: '0 0 40px 40px',
  },
  hidden: {
    visibility: 'hidden',
  },
  icon: {
    animation: 'none !important',
    color: '#000000',
    fontSize: '1.3em',
    left: '50%',
    position: 'absolute',
    strokeWidth: '1.5px',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  iconExpanded: {
    color: '#000000',
  },
  iconLoading: {
    color: '#000 !important',
    height: '18px !important',
    margin: '0 !important',
    width: '18px !important',
  },
  iconLovedIt: {
    color: theme.palette.red.main,
  },
  iconPopped: {
    color: theme.palette.text.primary,
  },
  iconPoppedModern: {
    fill: 'black',
  },
  popButton: {
    backgroundColor: '#FFF',
    borderRadius: 0,
    boxShadow: 'none !important',
    color: '#666',
    height: theme.spacing(bubbleSize),
    minWidth: 0,
    width: theme.spacing(bubbleSize),
  },
  popButtonContainer: {
    '&:last-child': {
      borderRadius: theme.spacing(3, 0, 0, 3),
    },
    '&:last-child button': {
      borderRadius: theme.spacing(3, 0, 0, 3),
    },
    '&:last-child pm-pop-count': {
      borderRadius: theme.spacing(3, 0, 0, 3),
    },
    backgroundColor: '#FFF',
    float: 'right',
    position: 'relative',
  },
  popCountContainer: {
    height: theme.spacing(9),
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    transition: 'width 100ms ease-in-out',
    width: theme.spacing(bubbleSize),
  },
  popCountContainerExpanded: {
    width: theme.spacing(bubbleSize * 5),
  },
  toggleButton: {
    background: 'none !important',
    borderRadius: theme.spacing(3),
    boxShadow: 'none !important',
    color: '#666',
    float: 'right',
    height: theme.spacing(bubbleSize),
    minWidth: 0,
    position: 'relative',
    width: theme.spacing(bubbleSize),
  },
  toggleButtonExpanded: {
    borderRadius: theme.spacing(0, 3, 3, 0),
    color: '#666 !important',
  },
  toggleButtonSelected: {
    color: '#000',
  },
  toggleContainer: {
    backgroundColor: 'white !important',
    borderRadius: theme.spacing(3),
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.25)',
    height: theme.spacing(bubbleSize),
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    transition: 'width 100ms ease-in-out',
    width: theme.spacing(bubbleSize),
  },
  toggleContainerExpanded: props => ({
    width: props.showWannaTry ?
      (props.disableLikes ? theme.spacing(4 * 5) : theme.spacing(bubbleSize * 5)) :
      (props.disableLikes ? theme.spacing(4 * 5) : theme.spacing(bubbleSize * 4)),
  }),
} as const satisfies StyleRules<Pick<SharedPopBubbleProps, 'disableLikes' | 'poppableType' | 'showWannaTry'>>);
