import { connect, type ConnectedProps } from 'react-redux';
import type {
  UpdateMenuItemPopMutationFn,
  UpdateMenuItemPopMutationVariables,
} from '~/libs/gql/mutations/menus/updateMenuItemPopMutation.generated';
import type {
  UpdateCalendarEventPopMutationFn,
  UpdateCalendarEventPopMutationVariables,
} from '~/libs/gql/mutations/calendar_events/updateCalendarEventPopMutation.generated';
import { openVipV2Modal, setVipData } from '~/shared/ModalActions';

export const connector = connect(() => ({}), {
  openVipV2Modal,
  setVipData,
});

export interface SharedPopBubbleProps {
  className?: string | null;
  disableLikes?: boolean;
  menuItemId?: number;
  poppableId: number;
  poppableType: 'Dish' | 'CalendarEvent';
  // Actually only used in PopBubble, but this makes types simpler
  showWannaTry?: boolean;
}

export interface InnerSharedPopBubbleProps extends SharedPopBubbleProps, ConnectedProps<typeof connector> {
  restaurantId: number;
}

export type PopMutationVariables = UpdateMenuItemPopMutationVariables | UpdateCalendarEventPopMutationVariables;
export type PopMutationFn = UpdateMenuItemPopMutationFn | UpdateCalendarEventPopMutationFn;
