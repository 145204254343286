import { readableFontColor } from '../../../utils/colors';

export default theme => ({
  reserveCall: {
    '& a': {
      color: readableFontColor('#FFFFFF', theme.palette.primary.main),
      fontSize: '1em',
      fontWeight: 'bold',
    },
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  reserveContainer: {
    '& .pm-reservation-widget-resy': {
      marginTop: theme.spacing(4),
    },
    '& .pm-reservation-widget-yelp': {
      marginTop: theme.spacing(4),
    },
  },
  reserveContent: {
    fontSize: '1em',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  reserveHeading: {
    color: readableFontColor('#FFFFFF', theme.palette.primary.main),
    fontSize: '1em',
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  reserveOr: {
    '& span': {
      backgroundColor: 'white',
      display: 'inline-block',
      lineHeight: `${theme.spacing(4)}px`,
      padding: theme.spacing(0, 2),
      position: 'relative',
      textTransform: 'uppercase',
      top: theme.spacing(2),
    },
    borderBottom: '1px solid #000',
    textAlign: 'center',
  },
  reviewButton: {
    marginTop: theme.spacing(4),
  },
  reviewsContainer: {
    margin: theme.spacing(4, 0),
  },
  tab: {
    '&:first-child': {
      borderTopLeftRadius: '4px',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:last-child': {
      borderTopRightRadius: '4px',
    },
    backgroundColor: 'white',
    color: 'black !important',
    minWidth: 0,
    opacity: '1 !important',
  },
  tabsContainer: {
    backgroundColor: 'white',
    borderRadius: '4px',
    marginTop: theme.spacing(4),
  },
  tabsSticky: {
    paddingTop: '0 !important',
    position: 'sticky',
    top: 0,
    zIndex: 6,
  },
});
