export default theme => ({
  defaultEngagementIcons: {
    '& > button': {
      '&:last-child': {
        color: '#000',
        marginRight: 0,
        width: theme.spacing(5.5),
      },
      borderRadius: '50px',
      boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25) !important',
      height: theme.spacing(5.5),
      marginRight: theme.spacing(1.5),
    },
    '& > button.modern-love-pop-button': {
      '& svg': {
        '&.active': {
          color: '#F04649',
          fill: '#F04649',
        },
        marginRight: theme.spacing(1.5),
      },
      alignItems: 'center',
      color: '#000',
      display: 'flex',
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px ${theme.spacing(1.5)}px ${theme.spacing(2.25)}px`,
      textTransform: 'lowercase',
      width: 'auto',
    },
    display: 'flex',
    justifyContent: 'flex-start',
  },
});
