export default theme => ({
  container: {
    backgroundColor: theme.consumer.useLegacyDishLayout ? 'black' : 'white',
    fontFamily: theme.typography.fontFamily && `"${theme.typography.fontFamily}"`,
    marginBottom: '-90px', // Bring up footer
  },
  containerModal: {
    marginBottom: '0 !important',
  },
  containerTop: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      flexWrap: 'nowrap',
      marginBottom: 0,
    },
    flexDirection: 'row-reverse',
    marginBottom: theme.spacing(-2),
  },
  containerTopModal: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(-2),
    },
    marginBottom: 0,
    marginTop: 0,
  },
  mobileCloseButton: {
    color: theme.consumer.useLegacyDishLayout ? 'white' : theme.consumer.primaryColorOnWhite,
    fontSize: '1.5em',
    position: 'relative',
    zIndex: 2,
  },
  mobileShareButton: {
    color: theme.consumer.useLegacyDishLayout ? 'white' : theme.consumer.primaryColorOnWhite,
    fontSize: '1.5em',
    position: 'absolute',
    right: 0,
    top: theme.spacing(2),
  },
  mobileTop: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    display: 'none',
    height: theme.spacing(9),
    padding: theme.spacing(2, 0),
    position: 'relative',
  },
  modernDishLayoutButtonFooter: {
    '& .add-to-cart-modal-footer': {
      [theme.breakpoints.down('sm')]: {
        left: '8px',
      },
      '& div[field="quantity"]': {
        '& > div': {
          width: '100%',
        },
      },
      bottom: 0,
      left: 0,
      width: 'calc(100% - 32px)',
    },
  },
  modernDishLayoutButtonFooterCart: {
    '& .add-to-cart-modal-footer': {
      [theme.breakpoints.down('sm')]: {
        left: '-16px',
      },
      '& .MuiIconButton-colorSecondary': {
        color: '#000 !important',
      },
      '& div[field="quantity"]': {
        '& > div': {
          width: '100%',
        },
      },
      bottom: 0,
      left: '-16px',
      width: '100%',
    },
  },
  modernDishLayoutContainer: {
    '& .details-container-top-modal': {
      '& > div': {
        '&:last-child': {
          '& > section': {
            height: '100%',
          },
          background: '#000',
          padding: 0,
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: '100% !important',
        },
        flex: '1 0 50% !important',
        height: 'calc(90vh - 10px)',
        maxWidth: '50% !important',
        padding: '16px 16px 4px 16px',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
      [theme.breakpoints.up('lg')]: {
        margin: '3vh 0 0 0',
        width: '100%',
      },
      height: '100%',
      marginTop: '3vh',
      overflowY: 'hidden',
    },
    '& .gallery-container-modern': {
      [theme.breakpoints.up('lg')]: {
        margin: '3vh 0 0 0',
      },
      height: '100%',
      margin: 0,
      padding: 0,
    },
    '& .item-form-submit-btn button': {
      '& span': {
        fontSize: '16px',
        textTransform: 'none',
      },
      borderRadius: '4px',
      color: '#fff',
      display: 'flex',
      justifyContent: 'space-between',
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    '& .menu-item-modifiers-container': {
      '& div[aria-label="Size"] > label': {
        '& > span:last-child': {
          width: '100%',
        },
        borderBottom: '#cccccc 1px solid',
        height: '56px',
        margin: 0,
        padding: `${theme.spacing(1.5)}px 0`,
      },
    },
    '& .modern-menu-item-extra-option': {
      '& .pm-form-group': {
        '& > label': {
          '& > span:last-child': {
            width: '100%',
          },
          margin: '7px 0',
          width: '100%',
        },
        display: 'flex !important',
        justifyContent: 'flex-start',
      },
      '& > div': {
        width: '100%',
      },
      borderBottom: '#cccccc 1px solid',
      display: 'flex',
      height: '56px',
      margin: 0,
    },
    '& .pm-menu-item-details-name': {
      marginBottom: theme.spacing(1),
      textAlign: 'left',
    },
    '& .review-form-modern': {
      '& button[type="submit"]': {
        borderRadius: '4px',
      },
      background: '#FFF',
      bottom: 0,
      padding: theme.spacing(2),
      position: 'sticky',
    },
    height: '90%',
    margin: 'auto',
    maxWidth: '1074px',
    position: 'relative',
    width: '80vw',
  },
  modernDishLayoutContainerLayoutA: {
    '& .details-container-top-modal': {
      '& > div': {
        '&:first-child': {
          maxWidth: '100% !important',
        },
        '&:last-child': {
          display: 'none',
        },
        height: 'calc(90vh + 3.5px)',
      },
      [theme.breakpoints.up('lg')]: {
        margin: '3vh -16px -16px -16px',
        width: 'calc(100% + 32px)',
      },
    },
    '& .gallery-container-modern': {
      [theme.breakpoints.up('lg')]: {
        margin: 0,
      },
    },
    '& .modern-info-top': {
      position: 'static',
    },
    maxWidth: '100vw',
    width: '530px',
  },
  noPhotos: {
    '& .add-to-cart-modal-footer': {
      [theme.breakpoints.down('sm')]: {
        marginTop: '16px',
      },
    },
    '& .details-container-top-modal': {
      '& > div': {
        '& > div': {
          [theme.breakpoints.down('sm')]: {
            padding: '16px 16px 0px 16px',
          },
          height: '100%',
          padding: '16px 0 0 0',
        },
        background: '#FFF !important',
        display: 'block !important',
        maxWidth: '100% !important',
      },
      [theme.breakpoints.down('sm')]: {
        height: '100vh',
      },
      height: 'calc(90vh - 10px)',
    },
    '& .modern-info-top > button:first-child': {
      [theme.breakpoints.down('md')]: {
        right: theme.spacing(2),
        top: theme.spacing(2),
      },
    },
  },
});
