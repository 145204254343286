import React from 'react';
import type { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Tooltip } from '@popmenu/common-ui';

import type { DishTagObject } from '~/libs/gql/types';
import { readableFontColor } from '../../utils/colors';
import { classNames } from '../../utils/withStyles';

interface DishTagsProps {
  allSelectedDishTags: DishTagObject[];
  classes?: ClassNameMap;
}

// raise z-index of dish tags using CSS
const DishTags = ({
  allSelectedDishTags = [],
  classes = {},
}: DishTagsProps) => {
  if (allSelectedDishTags.length === 0) {
    return null;
  }
  return (
    <div className={classes.dishTagsContainer}>
      {allSelectedDishTags.map(tag => (
        tag.imageIconUrl ? (
          <Tooltip
            key={tag.id}
            title={tag.name}
            aria-label={tag.name}
            placement="top"
          >
            <img
              alt={tag.name}
              className={classNames('pm-dish-tag-image', classes.dishTag, classes.dishTagImg)}
              src={tag.imageIconUrl}
            />
          </Tooltip>
        ) : (
          <Tooltip
            key={tag.id}
            title={tag.name}
            aria-label={tag.name}
            placement="top"
          >
            <span
              aria-label={tag.name}
              className={classNames('pm-dish-tag', classes.dishTag)}
              style={{
                background: tag.color,
                color: readableFontColor(tag.color),
              }}
            >
              {tag.abbr}
            </span>
          </Tooltip>
        )
      ))}
    </div>
  );
};

export default DishTags;
